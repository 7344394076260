import { Helmet } from 'react-helmet';
import brook from 'data/brook.json';
import React, { Fragment, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';

import Footer from 'container/footer/FooterOne';
import LayoutDefault from 'layouts/LayoutDefault';
import Header from 'components/header/Header';
import Awards from 'container/awards/homes/Awards';
import ServiceItemTwo from 'components/service/ServiceItemTwo';
import NewCard from 'components/news/NewCard';
import NewBigCard from 'components/news/NewBigCard';
import SlickSlider from 'components/slick';

import companyData from 'data/testimonial/company.json';
import newsData from 'data/news/news.json';
import newsBigCardData from 'data/news/newsBigCard.json';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';
import { useTranslation } from 'react-i18next';

const News = () => {
  gsap.registerPlugin(ScrollTrigger);

  const scrollAnimationRef = useRef();
  const { t } = useTranslation();
  const sliderSettings = {
    speed: 5000,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    swipeToSlide: true,
    centerMode: true,
    focusOnSelect: true,
    arrows: false,
    dots: false,
    responsive: [
      {
        breakpoint: 801,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 577,
        settings: {
          slidesToShow: 3
        }
      }
    ]
  };

  return (
    <Fragment>
      <Helmet>
        <title>{`Noticias | ${brook.title}`}</title>
      </Helmet>
      <LayoutDefault
        className="template-color-4 template-font-1"
        refContainer={scrollAnimationRef}
      >
        <Header />
        <div className="news-content">
          <div className="page-title">
            <h1 dangerouslySetInnerHTML={{ __html: t('news_title') }} />
          </div>

          <div className="news-cards-container" style={{ marginTop: '42px' }}>
            <NewBigCard
              key={newsBigCardData[0].id}
              thumb={newsBigCardData[0].thumb}
              title={newsBigCardData[0].title}
              description={newsBigCardData[0].description}
              date={newsBigCardData[0].date}
              relink={newsBigCardData[0].link}
              source={newsBigCardData[0].source}
            />
            {newsData.map((newInfo) => {
              return (
                <NewCard
                  key={newInfo.id}
                  thumb={newInfo.thumb}
                  title={newInfo.title}
                  description={newInfo.description}
                  date={newInfo.date}
                  relink={newInfo.link}
                  source={newInfo.source}
                />
              );
            })}
          </div>
        </div>
        <Container fluid="sm" style={{ marginTop: 50 + 'px' }}>
          <Row>
            <Col xs={12} className="text-center">
              <div className="brook-section-title text-center testimonial-airbag">
                <h3
                  dangerouslySetInnerHTML={{
                    __html: t('general_section_testimonial_title')
                  }}
                />

                <p
                  className="text-description"
                  dangerouslySetInnerHTML={{
                    __html: t('general_section_testimonial_description')
                  }}
                />
              </div>
            </Col>
          </Row>
          <Row style={{ marginBottom: 50 + 'px' }}>
            <Col>
              <div className="company-container" style={{ marginTop: '42px' }}>
                <SlickSlider
                  settings={sliderSettings}
                  classes="cars-road brook-element-carousel slick-arrow-center arrow-transparent slick-gutter-15"
                >
                  {companyData.map((company) => (
                    <ServiceItemTwo key={company.id} data={company} />
                  ))}
                </SlickSlider>
              </div>
            </Col>
          </Row>
        </Container>

        <Awards bgColor={'black'} />
      </LayoutDefault>
      <Footer className="bg_color--3" />
    </Fragment>
  );
};

export default News;
