import React from 'react';
import Button from 'components/button';
import { useTranslation } from 'react-i18next';

const PricingCardTwo = (props) => {
  const { id } = props;
  const { t } = useTranslation();

  return (
    <>
      <div key={id} className="scale-up-center slide-fwd-center pricing-card">
        <div className="pricing-card-content">
          <p
            id="title-card"
            dangerouslySetInnerHTML={{
              __html: t('pricing_card_two_title')
            }}
          />
          <p
            id="everything-plus"
            dangerouslySetInnerHTML={{
              __html: t('pricing_card_two_airbagapp_characteristic1')
            }}
          />
          <p className="char-card-title">Airbag App</p>
          <ul>
            <li
              className="char-card "
              dangerouslySetInnerHTML={{
                __html: t('pricing_card_two_airbagapp_characteristic2')
              }}
            />
            <li
              className="char-card "
              dangerouslySetInnerHTML={{
                __html: t('pricing_card_two_airbagapp_characteristic3')
              }}
            />
            <li
              className="char-card "
              dangerouslySetInnerHTML={{
                __html: t('pricing_card_two_airbagapp_characteristic4')
              }}
            />
            <li
              className="char-card "
              dangerouslySetInnerHTML={{
                __html: t('pricing_card_two_airbagapp_characteristic5')
              }}
            />
          </ul>
          <p className="char-card-title">Airbag DMS</p>
          <ul>
            <li
              className="char-card "
              dangerouslySetInnerHTML={{
                __html: t('pricing_card_two_airbagdms_characteristic2')
              }}
            />
            <li
              className="char-card "
              dangerouslySetInnerHTML={{
                __html: t('pricing_card_two_airbagdms_characteristic3')
              }}
            />
            <li
              className="char-card "
              dangerouslySetInnerHTML={{
                __html: t('pricing_card_two_airbagdms_characteristic4')
              }}
            />
            <li
              className="char-card "
              dangerouslySetInnerHTML={{
                __html: t('pricing_card_two_airbagdms_characteristic5')
              }}
            />
            <li
              className="char-card "
              dangerouslySetInnerHTML={{
                __html: t('pricing_card_two_airbagdms_characteristic8')
              }}
            />
          </ul>
          <p className="char-card-title">Airbag X-ray</p>
          <ul>
            <li
              className="char-card "
              dangerouslySetInnerHTML={{
                __html: t('pricing_card_two_airbagxray_characteristic1')
              }}
            />
            <li
              className="char-card "
              dangerouslySetInnerHTML={{
                __html: t('pricing_card_two_airbagxray_characteristic2')
              }}
            />
            <li
              className="char-card "
              dangerouslySetInnerHTML={{
                __html: t('pricing_card_two_airbagcray_characteristic3')
              }}
            />
          </ul>
          <div id="buy-button-card">
            <Button
              type={'link'}
              btnText={t('pricing_card_quote_button')}
              btnLink={'/cotizacion'}
              arrow={false}
              size={'sd'}
              color={'theme'}
              btnStyle={'bullet'}
            />
          </div>
          <Button
            type={'link'}
            btnText={t('pricing_card_white_button')}
            btnLink={'/contacto'}
            arrow={false}
            size={'sd'}
            color={'theme-secondary'}
            btnStyle={'bullet'}
          />
        </div>
      </div>
    </>
  );
};
export default PricingCardTwo;
