import React from 'react';
import Button from 'components/button';

const TestimonialItemOne = ({ data, classes, setPlayVideo, setVideoUrl }) => {
  return (
    <div className={`testimonial testimonial_style--1 ${classes && classes}`}>
      <div className="content">
        <img
          className="quotes-left"
          src={require('assets/img/icons/quotes-left.svg').default}
          alt="quotes left"
        />

        <p className="quote">{data.quote}</p>
        <div className="testimonial-info">
          <div className="client-info">
            <h6>-{data.client.name}</h6>
          </div>
          <div className="button-testimonial-card">
            <Button
              type={''}
              onClick={() => {
                setPlayVideo(true);
                setVideoUrl(data.videoUrl);
              }}
              btnText={'Ver video'}
              arrow={true}
              size={'sd'}
              color={'theme'}
              btnStyle={'bullet'}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestimonialItemOne;
