import { Helmet } from 'react-helmet';
import brook from 'data/brook.json';
import React, { Fragment, useLayoutEffect, useRef } from 'react';

import Footer from 'container/footer/FooterOne';
import LayoutDefault from 'layouts/LayoutDefault';
import Header from 'components/header/Header';
import HeroService from 'container/hero-banner/HeroService';
import FunFactService from 'container/funfact/FunfactServiceOne';
import OtherServiceSection from 'container/service/OtherServiceSection';
import Awards from 'container/awards/homes/Awards';
import Counter from 'container/counter/Counter';
import Testimonial from 'container/testimonial/elements/TestimonialAirbag';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';

import heroImage from 'assets/img/revolution/hero-service-1.svg';
import heroImageTwo from 'assets/img/revolution/hero-service-2.svg';
import heroImageThree from 'assets/img/revolution/hero-service-3.svg';
import heroVideo from 'assets/img/revolution/Web_airbag_Animacion_01_Blanco.mp4';
import heroVideoTwo from 'assets/img/revolution/Web_airbag_Animacion_02_Amarillo.mp4';
import heroVideoThree from 'assets/img/revolution/Web_airbag_Animacion_03_Amarillo.mp4';

import { useTranslation } from 'react-i18next';

const ServiceOne = () => {
  const { t } = useTranslation();
  gsap.registerPlugin(ScrollTrigger);
  const addedAnimationRef = useRef(false);

  const scrollAnimationRef = useRef();

  useLayoutEffect(() => {
    const q = gsap.utils.selector(scrollAnimationRef);

    // remove panel interaction on mobile

    if (window.innerWidth <= 992) {
      const listP = document.querySelectorAll('.remove-panel-mobile');
      listP.forEach((element) => {
        element.classList.remove('panel');
      });
    }

    gsap.to(q('.dot-logo'), {
      fill: 'white',
      ease: 'none',
      duration: 0.1,
      scrollTrigger: {
        trigger: q('.change-logo-dot'),
        start: 'top 10%',
        scrub: true
        // markers: true,
      }
    });

    gsap.utils.toArray(q('.panel')).forEach((panel) => {
      let w = gsap.getProperty(panel, 'height');
      // console.log(w)
      gsap.timeline({
        scrollTrigger: {
          trigger: panel,
          start: 'bottom bottom',
          end: '+=' + w,
          pin: true,
          pinSpacing: false,
          scrub: true
          // markers: true,
        }
      });
    });

    let sections = gsap.utils.toArray(q('.panel-horizontal'));

    let scrollTween = gsap.to(sections, {
      xPercent: -100 * (sections.length - 1),
      ease: 'none',
      scrollTrigger: {
        trigger: q('.container-h'),
        pin: true,
        scrub: 0.1,
        //snap: directionalSnap(1 / (sections.length - 1)),
        end: '200%'
        // markers: true,
      }
    });

    function isInViewport(el) {
      const rect = el.getBoundingClientRect();
      // console.log(rect);
      return (
        //CHECK IF PARTIALY VISIBLE
        rect.top < window.innerHeight && rect.bottom >= 0
      );
    }

    const box = document.querySelector('.panel-after-h');

    const addAnim = () => {
      if (!addedAnimationRef.current && isInViewport(box)) {
        // Flip boolean flag to avoid entering here again as it executes onScroll
        addedAnimationRef.current = true;
        // Add animation
        gsap.utils.toArray(q('.panel-after-h')).forEach((panel) => {
          ScrollTrigger.create({
            trigger: panel,
            start: 'bottom bottom',
            end: 'bottom top',
            pin: true,
            pinSpacing: false,
            scrub: true
          });
        });
      }
    };

    ScrollTrigger.create({
      trigger: q('.last-h'),
      containerAnimation: scrollTween,
      start: 'center 65%',
      end: 'center 51%',
      // onEnter: () => console.log("enter"),
      onLeave: () => {
        // console.log("leave")

        document.addEventListener('scroll', addAnim, {
          passive: true
        });

        gsap.to(q('.letter-logo'), {
          fill: 'white',
          ease: 'none',
          scrollTrigger: {
            trigger: q('.brook-our-awards'),
            start: 'top 10%',
            end: '1%',
            scrub: true
            // markers: true,
          }
        });

        gsap.to(q('.dot-logo'), {
          fill: 'rgb(255, 230, 0)',
          ease: 'none',
          scrollTrigger: {
            trigger: q('.change-logo-dot-normal'),
            start: 'top 10%',
            end: '5%',
            scrub: true
            // markers: true,
          }
        });

        //language

        if (window.innerWidth > 992) {
          gsap.to('.globe-l', {
            stroke: 'white',
            ease: 'none',
            scrollTrigger: {
              trigger: '.brook-our-awards',
              start: 'top 10%',
              end: '1%',
              scrub: true
              // markers: true,
            }
          });

          gsap.to('.globe-indicator', {
            color: 'white',
            ease: 'none',
            scrollTrigger: {
              trigger: '.brook-our-awards',
              start: 'top 10%',
              end: '1%',
              scrub: true
              // markers: true,
            }
          });
        }
      }

      // onEnterBack: () => console.log("enterBack"),
      // onLeaveBack: () => console.log("leaveBack"),
      // onToggle: self => console.log("active", self.isActive),
    });

    return () => {
      // console.log(ScrollTrigger.getAll());
      ScrollTrigger.getAll().forEach((st) => st.kill());
      document.removeEventListener('scroll', addAnim, { capture: false });
      // console.log(ScrollTrigger.getAll());
    };
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>{`App | ${brook.title}`}</title>
      </Helmet>
      <LayoutDefault
        className="template-color-4 template-font-1"
        refContainer={scrollAnimationRef}
      >
        <Header />

        <div className="panel remove-panel-mobile">
          <HeroService
            title={t('service_one_hero_title')}
            description={t('service_one_hero_description')}
            btnText={t('button_two')}
            heroImage={heroImage}
            heroVideo={heroVideo}
            videoClassName={'service-one'}
          />
        </div>

        <div className="panel bg_color--1 height-fact-service-one remove-panel-mobile">
          <FunFactService />
        </div>

        <div className="container-h">
          <div className="panel-horizontal change-logo-dot">
            <OtherServiceSection
              title={t('service_one_other_service_one_title')}
              content={t('service_one_other_service_one_description')}
              btnText={t('button_four')}
              btnLink={'/logistics'}
              heroImage={heroImageTwo}
              heroVideo={heroVideoTwo}
              videoClassName={'service-two'}
              videoConatinerClassName="d-flex justify-content-center"
            />
          </div>
          <div className="panel-horizontal last-h">
            <OtherServiceSection
              title={t('service_one_other_service_two_title')}
              content={t('service_one_other_service_two_description')}
              btnText={t('button_four')}
              btnLink={'/x-ray'}
              heroImage={heroImageThree}
              heroVideo={heroVideoThree}
              imageClassName={'service-three-image'}
              videoClassName={'service-three'}
            />
          </div>
        </div>

        <div className="change-logo-dot-normal panel-after-h min-vh-100 bg_color--1 d-flex flex-column justify-content-center">
          <Counter />
          <Testimonial />
        </div>

        <Awards bgColor={'black'} />
      </LayoutDefault>
      <Footer className=" bg_color--3 footer-service" />
    </Fragment>
  );
};

export default ServiceOne;
