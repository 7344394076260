import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Button from 'components/button';

const OtherServiceSection = ({
  title,
  content,
  btnText,
  btnLink,
  heroImage,
  imageClassName,
  videoClassName,
  heroVideo,
  videoConatinerClassName
}) => {
  return (
    <div className="brook-story-area section-ptb-xl bg_color--27 other-service-section before-panel">
      <Container fluid="sm">
        <Row>
          <Col lg={6}>
            <div className="">
              <div className="service airbag">
                <h1>{title}</h1>

                <p className="text-description">{content}</p>

                <Button
                  type={'link'}
                  btnText={btnText}
                  btnLink={btnLink}
                  arrow={false}
                  size={'sd'}
                  color={'theme-secondary'}
                  btnStyle={'bullet'}
                />
              </div>
            </div>
          </Col>

          <Col lg={6} className={`${imageClassName && imageClassName}`}>
            <div
              className={`other-service-video-container mt_md--40 mt_sm--40`}
            >
              <div className="architecture-story">
                <div className={`thumb ${videoConatinerClassName}`}>
                  {/* <img src={image} alt="architect" /> */}

                  <video
                    className={`hero-service-web ${videoClassName}`}
                    playsInline
                    autoPlay="autoplay"
                    loop
                    muted
                    poster={heroImage}
                  >
                    <source src={heroVideo} type="video/mp4" />
                  </video>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default OtherServiceSection;
