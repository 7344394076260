import React from 'react';

import { useTranslation } from 'react-i18next';

const NewsBigCard = (props) => {
  // Thumb Size: 300 x 300
  // Description: 100 charc or less
  const { thumb, title, description, date, relink, source } = props;
  const { t } = useTranslation();

  return (
    <div className="new-big-card-container" onClick={() => window.open(relink)}>
      <div
        className="new-big-card-image"
        style={{
          backgroundImage: `url(${require('assets/img/news/' + thumb)})`
        }}
      />
      <div>
        <div className="new-big-card-title">
          <h3>{title}</h3>
        </div>
        <div className="new-big-card-content">
          <p
            className="new-big-card-read"
            dangerouslySetInnerHTML={{
              __html: t(`${description}`)
            }}
          />
          <p className="new-big-card-source">- {source}</p>
        </div>
        <div className="new-big-card-footer">
          <p className="new-big-card-date">{date}</p>
          <p
            className="new-big-card-read"
            dangerouslySetInnerHTML={{
              __html: t('news_read_more')
            }}
          />
        </div>
      </div>
    </div>
  );
};
export default NewsBigCard;
