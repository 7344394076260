import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import BarLoader from 'react-spinners/BarLoader';
import { css } from '@emotion/react';

const LoadingTwo = () => {
  const override = css`
    display: block;
    margin: 0 auto;
  `;
  return (
    <div className="loading-two">
      <Container>
        <Row>
          <Col xs={12}>
            <div className="loading-inner text-center">
              <BarLoader
                color={'#FFE500'}
                loading={true}
                css={override}
                height={10}
                width={250}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default LoadingTwo;
