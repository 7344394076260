import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import TypedText from '../../components/typed-text';
import CounterItem from 'components/counter/CounterItemOne';

import { useTranslation } from 'react-i18next';

const Counter = () => {
  const { t } = useTranslation();
  return (
    <div className="brook-counterup-area section-pt-xl bg_color--1">
      <Container fluid="sm">
        <Row>
          <Col lg={12}>
            <div className="bk-title--default text-center d-lg-flex d-md-flex justify-content-center">
              <h3
                dangerouslySetInnerHTML={{
                  __html: t('general_section_counter_title_first')
                }}
              />
              <h3>
                <span>
                  {' '}
                  <TypedText
                    content={[
                      t('general_section_counter_typedtext_one'),
                      t('general_section_counter_typedtext_two'),
                      t('general_section_counter_typedtext_three'),
                      t('general_section_counter_typedtext_four')
                    ]}
                    typeSpeed={50}
                    backSpeed={50}
                    loop={true}
                  />{' '}
                </span>
              </h3>
            </div>

            <div className="bk-title--default text-center">
              <h3
                dangerouslySetInnerHTML={{
                  __html: t('general_section_counter_title_second')
                }}
              />
            </div>
          </Col>
        </Row>

        <Row className="mt--40">
          <Col xs={12}>
            <div className="kilometers-counter">
              <CounterItem count={16203498} />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Counter;
