import React from 'react';

const ServiceItemTwo = ({ data }) => {
  return (
    <div className="service service--3 hover-theme-color">
      <div className="thumb">
        <div className="logo-container">
          <img
            src={require('assets/img/service/service-3/' + data.thumb)}
            alt={data.title}
          />
        </div>
      </div>
    </div>
  );
};

export default ServiceItemTwo;
