import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import HttpApi from 'i18next-http-backend';
import './index.scss';
import App from './App';
import { PostHogProvider } from 'posthog-js/react';

import Loading from './container/loading/LoadingTwo';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .use(HttpApi)
  .init({
    supportedLngs: ['es', 'en'],
    fallbackLng: 'es',
    detection: {
      order: [
        'cookie',
        'navigator',
        'htmlTag',
        'localStorage',
        'path',
        'subdomain'
      ],
      caches: ['cookie']
    },
    backend: {
      loadPath:
        process.env.PUBLIC_URL + '/assets/locales/{{lng}}/translation.json'
      // loadPath: '/assets/locales/{{lng}}/translation.json',
    }
    // react: {useSuspense:false},
  });

const loadingMarkup = <Loading />;

const options = {
  api_host: process.env.REACT_APP_PUBLIC_POSTHOG_HOST,
  autocapture: false
};

ReactDOM.render(
  <PostHogProvider
    apiKey={process.env.REACT_APP_PUBLIC_POSTHOG_KEY}
    options={options}
  >
    <Suspense fallback={loadingMarkup}>
      <App />
    </Suspense>
  </PostHogProvider>,
  document.getElementById('root')
);
