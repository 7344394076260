import { Helmet } from 'react-helmet';
import brook from 'data/brook.json';
import React, { Fragment, useLayoutEffect, useRef } from 'react';

import Footer from 'container/footer/FooterOne';
import LayoutDefault from 'layouts/LayoutDefault';
import Header from 'components/header/Header';
import Awards from 'container/awards/homes/Awards';
import Counter from 'container/counter/Counter';
import Testimonial from 'container/testimonial/elements/TestimonialAirbag';
import ContactForm from 'container/contact/ContactSection';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';

const ContactAirbag = () => {
  gsap.registerPlugin(ScrollTrigger);

  const scrollAnimationRef = useRef();

  useLayoutEffect(() => {
    const q = gsap.utils.selector(scrollAnimationRef);

    // remove panel interaction on mobile

    if (window.innerWidth <= 992) {
      const listP = document.querySelectorAll('.remove-panel-mobile');
      listP.forEach((element) => {
        element.classList.remove('panel');
      });
    }

    // Logo color

    gsap.to(q('.letter-logo'), {
      fill: 'white',
      ease: 'none',
      scrollTrigger: {
        trigger: q('.brook-our-awards'),
        start: 'top 10%',
        end: '1%',
        scrub: true
        // markers: true,
      }
    });

    //language

    if (window.innerWidth > 992) {
      gsap.to('.globe-l', {
        stroke: 'white',
        ease: 'none',
        scrollTrigger: {
          trigger: '.brook-our-awards',
          start: 'top 10%',
          end: '1%',
          scrub: true
          // markers: true,
        }
      });

      gsap.to('.globe-indicator', {
        color: 'white',
        ease: 'none',
        scrollTrigger: {
          trigger: '.brook-our-awards',
          start: 'top 10%',
          end: '1%',
          scrub: true
          // markers: true,
        }
      });
    }

    gsap.to(q('.dot-logo'), {
      fill: 'rgb(255, 230, 0)',
      ease: 'none',
      scrollTrigger: {
        trigger: q('.change-logo-dot-normal'),
        start: 'top 10%',
        end: '5%',
        scrub: true
        // markers: true,
      }
    });

    gsap.utils.toArray(q('.panel')).forEach((panel) => {
      gsap.timeline({
        scrollTrigger: {
          trigger: panel,
          start: 'bottom bottom',
          end: 'bottom top',
          pin: true,
          pinSpacing: false,
          scrub: true
          // markers: true,
        }
      });
    });

    return () => {
      // console.log(ScrollTrigger.getAll());
      ScrollTrigger.getAll().forEach((st) => st.kill());
      // console.log(ScrollTrigger.getAll());
    };
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>{`Contacto | ${brook.title}`}</title>
      </Helmet>
      <LayoutDefault
        className="template-color-4 template-font-1"
        refContainer={scrollAnimationRef}
      >
        <Header />

        <div className="bg_color--25">
          <ContactForm />
        </div>

        <div className="panel change-logo-dot-normal min-vh-100 bg_color--1 d-flex flex-column justify-content-center">
          <Counter />
          <Testimonial />
        </div>

        <Awards bgColor={'black'} />
      </LayoutDefault>
      <Footer className="bg_color--3" />
    </Fragment>
  );
};

export default ContactAirbag;
