import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import gsap from 'gsap';

const Button = ({
  type,
  linkType,
  btnText,
  btnLink,
  size,
  btnStyle,
  color,
  onClick,
  className,
  arrow
}) => {
  useEffect(() => {
    if (window.innerWidth > 992) {
      gsap.utils.toArray('.button-anim').forEach((el) => {
        let items = el.querySelectorAll('div');

        var tl = gsap.timeline();

        tl.fromTo(
          items,
          { x: 0 },
          {
            duration: 0.8,
            x: '100%'
          }
        ).reverse();

        items.forEach((item) => {
          item.addEventListener('mouseover', () => {
            tl.play();
          });
        });

        el.addEventListener('mouseleave', () => {
          // tl.timeScale(3).reverse()
          tl.reverse();
        });
      });
    }
  }, []);

  return type === 'link' ? (
    linkType === 'external' ? (
      <a
        className={`brook-btn bk-btn-${color} btn-${size}-size btn-${btnStyle} ${className}`}
        href={btnLink}
        onClick={onClick}
        target="_blank"
        rel="noreferrer noopener"
      >
        {arrow === false || undefined ? null : (
          <img
            className="arrow-right"
            src={require('assets/img/icons/arrow-right-black.svg').default}
            alt="arrow right"
          />
        )}
        <span>{btnText}</span>
      </a>
    ) : arrow === false || undefined ? (
      <Link
        className={`brook-btn bk-btn-${color} btn-${size}-size btn-${btnStyle} ${className} button-anim`}
        to={process.env.PUBLIC_URL + btnLink}
        onClick={onClick}
      >
        <span>{btnText}</span>
        <div className="onbutton">
          <span>{btnText}</span>
        </div>
      </Link>
    ) : (
      <Link
        className={`brook-btn bk-btn-${color} btn-${size}-size btn-${btnStyle} ${className} button-anim`}
        to={process.env.PUBLIC_URL + btnLink}
        onClick={onClick}
      >
        <span>{btnText}</span>
        <img
          className="arrow-right"
          src={require('assets/img/icons/arrow-right-white.svg').default}
          alt="arrow right"
        />

        <div className="onbutton with-arrow">
          <img
            className="arrow-right"
            src={require('assets/img/icons/arrow-right-black.svg').default}
            alt="arrow right"
          />
          <span>{btnText}</span>
        </div>
      </Link>
    )
  ) : (
    <button
      className={`brook-btn bk-btn-${color} btn-${size}-size btn-${btnStyle} ${className} button-anim`}
      onClick={onClick}
    >
      {arrow === false || undefined ? null : (
        <img
          className="arrow-right"
          src={require('assets/img/icons/arrow-right-black.svg').default}
          alt="arrow right"
        />
      )}
      <span>{btnText}</span>
      <div className="onbutton">
        <span>{btnText}</span>
      </div>
    </button>
  );
};

Button.defaultProps = {
  type: 'link',
  size: 'sm',
  btnStyle: 'theme',
  linkType: 'internal'
};

export default Button;
