import CardOne from 'components/card/CardOne';
import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import imageOne from 'assets/img/service/service-2-illustration-1.svg';
import imageTwo from 'assets/img/service/service-2-illustration-2.svg';
import imageThree from 'assets/img/service/service-2-illustration-3.svg';
import imageFour from 'assets/img/service/service-2-illustration-4.svg';
import imageFive from 'assets/img/service/service-2-illustration-5.svg';
import { useTranslation } from 'react-i18next';

const FunFactServiceTwo = () => {
  const { t } = useTranslation();
  return (
    <div className="section-ptb-xl">
      <Container fluid="sm">
        <Row>
          <Col xs={12} lg={8} className="d-flex align-items-stretch">
            <CardOne
              content={t('service_two_fact_card_one_description')}
              title={t('service_two_fact_card_one_title')}
              cardImage={imageOne}
              imageClass={'align-self-center mt--40 mb--40'}
            />
          </Col>

          <Col xs={12} lg={4} className="d-flex align-items-stretch">
            <CardOne
              content={t('service_two_fact_card_two_description')}
              title={t('service_two_fact_card_two_title')}
              cardImage={imageTwo}
              imageClass={'align-self-center mt--40'}
            />
          </Col>

          <Col xs={12} lg={6} className="d-flex align-items-stretch">
            <CardOne
              content={t('service_two_fact_card_three_description')}
              title={t('service_two_fact_card_three_title')}
              cardImage={imageThree}
              imageClass={'align-self-center mt--30 pl_sm--20 pr_sm--20'}
            />
          </Col>

          <Col xs={12} lg={6} className="d-flex align-items-stretch">
            <CardOne
              content={t('service_two_fact_card_four_description')}
              title={t('service_two_fact_card_four_title')}
              cardImage={imageFour}
              imageClass={'align-self-center mt--30 mb--40'}
            />
          </Col>

          <Col xs={12} lg={12} className="d-flex align-items-stretch">
            <CardOne
              content={t('service_fact_card_general_description')}
              title={t('service_fact_card_general_title_two')}
              cardImage={imageFive}
              imageClass={'col-lg-4 align-lg-self-end pr_md--100 pl_md--100'}
              contentClass={'col-lg-8'}
              classes={'flex-lg-row'}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default FunFactServiceTwo;
