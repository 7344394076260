import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Button from 'components/button';

const AppLinkContainer = () => {
  return (
    <div className="error-not-found">
      <Container fluid="sm">
        <Row>
          <Col xs={12}>
            <div className="error-inner text-center d-flex flex-column">
              <h3 className="heading heading-h3 text-white">
                Serás redirigido en unos segundos..
              </h3>
              <div className="error-text mt--20">
                <p className="text-white">
                  O puedes dar click al siguiente botón para abir el enlace a la
                  aplicación.
                </p>
                <div className="error-button-group mt--40 d-flex justify-content-center align-items-center">
                  <div className="mr--40 return-button">
                    <Button
                      type="button"
                      btnText="Abrir aplicación"
                      btnStyle="bullet"
                      size="sd"
                      color="theme"
                      arrow={false}
                      onClick={() =>
                        window.location.replace(
                          'https://play.google.com/store/apps/details?id=com.airbag.app'
                        )
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AppLinkContainer;
