import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import ReactGA from 'react-ga4';
import PageContainer from 'container/page';
import PopupSearch from 'components/popup/PopupSearch';
import PopupMobileMenu from 'components/popup/PopupMobileMenu';
import { posthog } from 'posthog-js';

/* Google Analytics */
const ANALYTICS_TRACKING_ID = process.env.REACT_APP_GOOGLE_ANALYTICS;
if (ANALYTICS_TRACKING_ID) {
  ReactGA.initialize(ANALYTICS_TRACKING_ID);
}

/* PostHog */
const POSTHOG_ID = process.env.REACT_APP_PUBLIC_POSTHOG_KEY;

const LayoutDefault = ({ children, className, revealFooter, refContainer }) => {

  const location = useLocation();

  // This useEffect is for tracking when a user changes location
  useEffect(() => {
    /* Google Analytics */
    if (ANALYTICS_TRACKING_ID) {
      // Send location change
      ReactGA.send({
        hitType: 'pageview',
        page: location.pathname
      });
    }

    /* PostHog */
    if (POSTHOG_ID) {
      // Capture new location
      posthog.capture('$pageview');
    }
  }, [location]);

  return (
    <PageContainer
      className={className}
      revealFooter={revealFooter}
      refContainer={refContainer}
    >
      <PopupSearch />
      <PopupMobileMenu />
      {children}
    </PageContainer>
  );
};

export default LayoutDefault;
