import React, { useLayoutEffect, useRef } from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Button from 'components/button';
import { ReactComponent as CarOnRoad } from 'assets/img/animation/car-on-the-road.svg';

import gsap from 'gsap';
import { ScrollTrigger, MotionPathPlugin } from 'gsap/all';

import { useTranslation } from 'react-i18next';

const AboutTwo = () => {
  const { t } = useTranslation();
  gsap.registerPlugin(MotionPathPlugin, ScrollTrigger);

  const scrollAnimationRef = useRef();

  useLayoutEffect(() => {
    const q = gsap.utils.selector(scrollAnimationRef);

    // Second yellow section animation

    const animation1 = gsap.set(q('.motionSVG'), { scale: 1, autoAlpha: 1 });
    const animation2 = gsap.set(q('.tractor'), { transformOrigin: '50% 50%' });

    // Currently in container/loading/LoadingOne.js

    // gsap.to(q(".motionSVG"), {
    //     scrollTrigger: {
    //         trigger: scrollAnimationRef.current,
    //         start: "top bottom",
    //         end: 'bottom top',
    //         scrub: 1,
    //         // markers: true,
    //         onUpdate: self => {
    //             gsap.to(q(".tractor"), { rotation: () => self.direction === 1 ? 0 : -180, overwrite: 'auto' });
    //         }
    //     },
    //     duration: 10,
    //     ease: "none",
    //     immediateRender: true,
    //     motionPath: {
    //         start: 0,
    //         end: 0.6,
    //         path: ".motionPath",
    //         align: ".motionPath",
    //         alignOrigin: [0.5, 0.5],
    //         autoRotate: 24,
    //     }
    // });

    const animation3 = gsap.timeline({
      scrollTrigger: {
        trigger: scrollAnimationRef.current,
        start: 'top bottom',
        end: 'bottom top',
        scrub: 1
        // markers: true
      }
    });

    animation3
      .add('start')
      .from(q('.plus-points'), { autoAlpha: 0, duration: 0.5 }, 'start')
      .to(q('.plus-points'), { yPercent: -30, duration: 1 }, 'start');

    return () => {
      animation1.kill();
      animation2.kill();
    };
  }, []);

  return (
    <div
      className="brook-story-area section-ptb-xl bg_color--24 before-panel scroll-animation-two"
      ref={scrollAnimationRef}
    >
      <Container fluid="sm">
        <Row>
          <Col lg={6}>
            <div className="">
              <div className="service airbag">
                <h3
                  dangerouslySetInnerHTML={{ __html: t('home_fact_two_title') }}
                />

                <p
                  className="text-description"
                  dangerouslySetInnerHTML={{
                    __html: t('home_fact_two_description')
                  }}
                />

                <Button
                  type={'link'}
                  btnText={t('button_three')}
                  btnLink={'/logistics'}
                  arrow={false}
                  size={'sd'}
                  color={'theme-secondary'}
                  btnStyle={'bullet'}
                />
              </div>
            </div>
          </Col>

          <Col lg={6} className="home-service-two-animation">
            <div className="mt_md--40 mt_sm--40">
              <div className="architecture-story">
                <div className="thumb d-flex justify-content-end">
                  <CarOnRoad />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default AboutTwo;
