import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

const TerminosCondicionesC = () => {
  return (
    <div className="brook-about-area pt--200 pt_md--120 pt_sm--100  pb--130 pb_md--100 pb_sm--80">
      <Container fluid="sm">
        <Row>
          <Col xs={12}>
            <div className="breadcrumb-inner mb--50">
              <h1
                className="heading-section"
                dangerouslySetInnerHTML={{ __html: 'Términos y condiciones' }}
              />
            </div>
            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html: 'Última actualización: Diciembre 2021'
              }}
            />

            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  'El presente documento es un acuerdo de términos y condiciones (los “Términos y Condiciones”) que rigen la prestación de los Servicios y el acceso y uso de la Plataforma (con las respectivas actualizaciones, modificaciones o cambios que pueda sufrir dicha Plataforma y/o las disposiciones de prestación de los Servicios) puesta a disposición por Airbag Technologies, S.A.P.I. de C.V. (“Airbag”) a sus Clientes conforme al Contrato de Prestación de Servicios (según dicho término se define más adelante) o, en su caso, al Statement of Work, celebrado entre cada uno de éstos y Airbag.'
              }}
            />

            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  'En caso de contradicción o conflicto entre lo señalado en estos Términos y Condiciones Generales y lo establecido en el Contrato de Prestación de Servicios, las disposiciones establecidas en el Contrato de Prestación de Servicios prevalecerán. Los términos utilizados en mayúscula inicial en el presente documento, pero no definidos expresamente en el mismo, tendrán el significado que se les atribuye en el Contrato de Prestación de Servicios.'
              }}
            />

            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  'Cualquier persona que use o acceda a la Plataforma y/o a los Servicios, ya sea directamente o en representación de otra persona, incluyendo representantes autorizados de cualquier empresa, sus empleados u otras personas que accedan a los Servicios podrán hacerlo sujetándose a estos Términos y Condiciones, la legislación aplicable y todos los demás avisos y políticas que rigen el uso de la Plataforma y la relación contractual entre el Cliente y Airbag y que son incorporados al presente por referencia.'
              }}
            />

            <p
              className="mb--50"
              dangerouslySetInnerHTML={{
                __html:
                  'Al hacer clic en el botón correspondiente para indicar que acepta estos Términos y Condiciones, o al acceder o utilizar la Plataforma, reconoce que ha leído y que comprende dichos Términos y Condiciones, los cuales tienen un carácter obligatorio y vinculante. En caso de no aceptarlos, deberá abstenerse de utilizar la Plataforma y/o los Servicios.'
              }}
            />

            <div className="breadcrumb-inner mb--50">
              <h2
                className="heading-section"
                dangerouslySetInnerHTML={{ __html: '1. Información general' }}
              />
            </div>

            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '1.1. Airbag es una sociedad legalmente constituida y válidamente existente conforme a las leyes de los Estados Unidos Mexicanos, plenamente facultada conforme a su objeto social para prestar los servicios para prestar los servicios detallados en estos Términos y Condiciones.'
              }}
            />
            <p
              className="mb--50"
              dangerouslySetInnerHTML={{
                __html:
                  '1.2. Airbag ofrece a sus Clientes diversos Servicios en la forma y términos que acuerde con los mismos en el respectivo Contrato de Prestación de Servicios, así como en los presentes Términos y Condiciones.'
              }}
            />

            <div className="breadcrumb-inner mb--50">
              <h2
                className="heading-section"
                dangerouslySetInnerHTML={{
                  __html: '2. Administradores de Cuenta, Usuarios y Cuentas'
                }}
              />
            </div>

            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '2.1. Para poder usar la Plataforma, el Cliente deberá designar a una o más personas (cada una de dichas personas, un “Administrador de Cuenta”) para que, entre otros, (i) actúen en su nombre y representación, (ii) proporcionen información respecto del Cliente, ya sea a través de la Plataforma o del equipo de activación o soporte de Airbag, y, en su caso, tengan acceso a los reportes respecto de los Servicios prestados por Airbag, (iii) lo obliguen con respecto a los Servicios, y (iv) designen a usuarios que podrán tener una cuenta activa dentro de dicha Plataforma (cada uno, un “Usuario”), así como a otros Administradores de Cuenta con diversos permisos y/o perfiles, según los mismos sean habilitados de tiempo en tiempo en la Plataforma.'
              }}
            />
            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '2.2. Cada Administrador de Cuenta y Usuario deberá registrarse (o solicitar a Airbag su registro) para acceder a la Plataforma (en lo sucesivo, una “Cuenta”). Para obtener dicha Cuenta, es necesario que el Administrador de Cuenta o Usuario tenga como mínimo 18 (dieciocho) años, así como que proporcione cierta información que al respecto sea requerida en la Plataforma, o que sea requerida por el equipo de activación o de soporte de Airbag.'
              }}
            />
            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '2.3. Airbag puede en cualquier momento solicitar cualquier tipo de documento o dato adicional a efecto de corroborar la Cuenta, la identidad del Administrador de Cuenta o Usuario, así como suspender temporal o definitivamente las Cuentas cuyos datos o información no hayan podido ser confirmados. En consecuencia, el Cliente autoriza a Airbag a obtener, verificar y registrar la información de la persona que cree la Cuenta con el fin de corroborar la identidad de la persona que cree o acceda a la cuenta. De manera enunciativa, mas no limitativa, Airbag podrá solicitar el nombre, domicilio, fecha de nacimiento y número de teléfono, entre otros, que permita a Airbag identificar al Usuario o al Administrador de Cuenta. Asimismo, Airbag podrá solicitar documentos de identificación del Usuario o Administrador de Cuenta.'
              }}
            />
            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '2.4. El Cliente reconoce que él es el único responsable de la información ingresada a la Plataforma, o proporcionada al equipo de Airbag para ser ingresada a la Plataforma a través de sus Administradores de Cuenta o Usuarios, así como de las operaciones y actos llevados a cabo en la Plataforma (incluyendo de aquellos llevados a cabo por cualquier Administrador de Cuenta o Usuario, en cuyo caso las acciones de dicho Administrador de Cuenta o Usuario se considerarán como realizadas por el propio Cliente). Airbag no será responsable de la exactitud, certeza o veracidad de la información u operaciones que ocurran en la Plataforma, ni de los Servicios de Terceros (según dicho término se define más adelante) que sean contratados por el Cliente. En consecuencia, el Cliente declara y garantiza a Airbag que cualquier información que el Cliente comparta con Airbag, ya sea directamente, o a través de cualquier Administrador de Cuenta o Usuario, a través de la Plataforma o al equipo de Airbag para su ingreso a la Plataforma, será responsabilidad exclusiva del Cliente y éste declara y garantiza a Airbag que cuenta con autorización suficiente para compartirla.'
              }}
            />
            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '2.5. El Cliente es el único responsable y se compromete a mantener de forma segura y confidencial las contraseñas y credenciales de acceso de las Cuentas, así como las contraseñas y credenciales de acceso de los Administradores de Cuenta y Usuarios. Asimismo, el Cliente tomará las medidas y se encargará de que los Administradores de Cuenta y los Usuarios tomen medidas razonables para proteger adecuadamente y mantener confidenciales las contraseñas y credenciales de las Cuentas. En caso de que el Cliente crea o sospeche que las contraseñas o credenciales de acceso de cualquier Cuenta han sido reveladas, puestas en peligro o se ha realizado el acceso por personas no autorizadas, deberá notificarlo inmediatamente a Airbag por escrito. Airbag se reserva el derecho de impedir el acceso a la Cuenta y/o a los Servicios en caso de que Airbag tenga motivo para creer que la Cuenta o las contraseñas o credenciales para la Cuenta han sido puestas en peligro o están siendo utilizadas en violación a los términos establecidos en los presentes Términos y Condiciones y/o la legislación aplicable. Asimismo, Airbag se reserva el derecho de rechazar cualquier solicitud de apertura de Cuenta, sin que esté obligado a comunicar las razones de ello, y sin que ello genere alguna responsabilidad para Airbag, así como suspender, inhabilitar o bloquear una Cuenta, o el acceso a la Plataforma o a los Servicios en los términos del presente.'
              }}
            />
            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '2.6. El Cliente será el único responsable de la obtención, mantenimiento y resguardo de cualquier equipo y de los servicios auxiliares necesarios para conectarse, acceder o utilizar la Plataforma incluidos, entre otros, acceso a Internet, equipos de red, teléfonos móviles, hardware, software y sistemas operativos.'
              }}
            />
            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '2.7. Adicionalmente, el Cliente, ya sea directamente o a través de sus Administradores de Cuenta, es responsable de revisar cualquier informe, archivo, información, documento o material (conjuntamente, los “Materiales”) publicado por Airbag en la Plataforma (o puesto a disposición por Airbag para revisión del Cliente), y deberá notificar a Airbag de cualquier inexactitud en dichos materiales tan pronto como sea posible o dentro del plazo de tiempo especificado por Airbag en el Material correspondiente.'
              }}
            />
            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '2.8. El Cliente acepta que, en la medida máxima permitida por la ley, cualquier acto que se lleve a través de la Plataforma en relación con los Servicios, incluyendo cualquier acto de un Administrador de Cuenta (por ejemplo, el hacer clic en cualquier botón), así como cualesquiera comunicaciones con el equipo de activación o de soporte de Airbag en los que el Cliente o el Administrador de Cuenta soliciten que se lleve a cabo algún acto, tendrán los mismos efectos como si dichos actos hubieran sido instruidos por el Cliente a Airbag por escrito.'
              }}
            />
            <p
              className="mb--50"
              dangerouslySetInnerHTML={{
                __html:
                  '2.9. El Cliente, a través de los presentes Términos y Condiciones, autoriza a Airbag a obtener y almacenar la información de la Cuenta, según sea necesario para que la Plataforma esté a su disposición.'
              }}
            />

            <div className="breadcrumb-inner mb--50">
              <h2
                className="heading-section"
                dangerouslySetInnerHTML={{
                  __html: '3. Contrato de Prestación de Servicios'
                }}
              />
            </div>

            <p
              className="mb--50"
              dangerouslySetInnerHTML={{
                __html:
                  '3.1. La prestación por parte de Airbag de cualquier servicio y el uso de la Plataforma está sujeta a estos Términos y Condiciones Generales, así como al Contrato de Prestación de Servicios o Statement of Work (el “Contrato de Prestación de Servicios”) que Airbag haya celebrado con el Cliente.'
              }}
            />

            <div className="breadcrumb-inner mb--50">
              <h2
                className="heading-section"
                dangerouslySetInnerHTML={{
                  __html: '4. Tarifas y Cambios a los Términos de Servicio'
                }}
              />
            </div>

            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '4.1. El Cliente acepta pagar las tarifas por la prestación de los Servicios de acuerdo con los términos y condiciones establecidos en el Contrato de Prestación de Servicios correspondiente.'
              }}
            />
            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '4.2. A menos que el Contrato de Prestación de Servicios establezca lo contrario, o en caso de que el mismo sea omiso al respecto, se aplicarán las siguientes disposiciones:'
              }}
            />
            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '4.2.1. Para efectos del cálculo del número de operadores inscritos en la Plataforma, cualquier operador del Cliente que esté inscrito por lo menos 15 (quince) días naturales del periodo en pago de la Plataforma, será considerado como inscrito y computará para efectos del cálculo de la cuota mensual por cada operador inscrito en la Plataforma que forma parte de la tarifa por los Servicios de Airbag.'
              }}
            />
            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '4.2.2. Las tarifas de los Servicios se basarán en los meses calendario en los que el Cliente esté inscrito (por ejemplo, si el Cliente tiene contratado un Servicio al inicio de un mes calendario determinado, ser cobrará al Cliente la tarifa correspondiente a dicho mes) y dichas tarifas aplicarán en su totalidad durante el mes calendario determinado.'
              }}
            />
            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '4.2.3. La facturación por los Servicios se hará de manera mensual.'
              }}
            />
            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '4.2.4. El Cliente deberá pagar las tarifas por concepto de la prestación de los Servicios dentro de los 15 (quince) días naturales siguientes a haber recibido la factura correspondiente. En caso de que el Cliente no pague dentro de este plazo, Airbag podrá llevar a cabo las acciones que se detallan en la Sección Vigencia y Terminación de estos Términos y Condiciones. El pago de las tarifas se realizará a través de las formas de pago que Airbag ponga a disposición del Cliente por escrito o a través de la Plataforma.'
              }}
            />
            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '4.2.5. Los pagos realizados por los Servicios conforme a las tarifas establecidas no son reembolsables.'
              }}
            />
            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '4.3. Airbag podrá cobrar tarifas adicionales por el procesamiento de excepciones, configuración y otros servicios especiales solicitados por el Cliente durante la vigencia del Contrato de Prestación de Servicios o de los presentes Términos y Condiciones.'
              }}
            />
            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '4.4. Sin perjuicio de cualquier disposición en contrario, Airbag se reserva el derecho de cambiar la tarifas de sus Servicios en cualquier momento. Para ello, se notificará al Cliente de cualquier cambio en las tarifas existentes con al menos 30 (treinta) días naturales antes de que el cambio de tarifa entre en vigor. En caso de que el Cliente no esté de acuerdo con el cambio de tarifa, deberá manifestarlo y cancelar los Servicios que tenga contratados dentro del plazo que estipule el aviso respectivo. En caso de no manifestar su negativa dentro de dicho plazo o el hecho de que el Cliente, a través de su Administrador de Cuenta, continúe usando la Plataforma y/o los Servicios una vez que la nueva tarifa entre en vigor, se entenderá que está de acuerdo con el cambio de tarifa.'
              }}
            />
            <p
              className="mb--50"
              dangerouslySetInnerHTML={{
                __html:
                  '4.5. En caso de que el Cliente acuerde con Airbag variar el número o características de los Servicios contratados a través del Contrato de Prestación de Servicios, dicha actualización entrará en vigor a partir de la celebración del nuevo Contrato de Prestación de Servicios, o el convenio modificatorio correspondiente, y el Cliente comenzará a partir de dicho momento a recibir acceso a las funciones y Servicios disponibles contratados. Las tarifas aplicables para cada uno de los Servicios contratados se detallarán en Contrato de Prestación de Servicios celebrado entre el Cliente y Airbag, o el convenio modificatorio correspondiente, y las mismas serán aplicables a partir del mes calendario en que contraten dichos Servicios.'
              }}
            />

            <div className="breadcrumb-inner mb--50">
              <h2
                className="heading-section"
                dangerouslySetInnerHTML={{ __html: '5. Servicios de Airbag' }}
              />
            </div>

            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '5.1. De conformidad con lo establecido en el Contrato de Prestación de Servicios, Airbag prestará al Cliente, directamente y a través de los Usuarios, los diversos servicios que se especifiquen en el Contrato de Prestación de Servicios respectivo (los “Servicios”). Dentro de dichos Servicios, se podrán incluir las siguientes funciones:'
              }}
            />
            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '5.1.1. Bloqueo de dispositivo móvil del Usuario al superar cierto kilometraje por hora.'
              }}
            />
            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '5.1.2. Seguimiento y monitoreo de trayecto del Usuario con ubicación y hora, el cual podrá incluir'
              }}
            />
            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '5.1.2.1. Aviso de activación y desactivación de la aplicación.'
              }}
            />
            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '5.1.2.2. Activación y desactivación de los datos móviles y servicio de llamadas.'
              }}
            />
            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html: '5.1.2.3. Inicio y fin de ruta.'
              }}
            />
            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html: '5.1.2.4. Aviso de disminución o exceso de velocidad.'
              }}
            />
            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html: '5.1.2.5. Registro de llamadas entrantes y salientes.'
              }}
            />
            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '5.1.2.6. Seguimiento de paradas, eventos y horarios de carga y descarga.'
              }}
            />
            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '5.2. Con el propósito de que Airbag esté en posibilidad de prestar los Servicios al Cliente, el Cliente deberá mantener, al menos, la siguiente información de cada uno de los Usuarios inscritos en la Plataforma:'
              }}
            />
            <p
              className="mb--30"
              dangerouslySetInnerHTML={{ __html: '5.2.1. Nombre completo.' }}
            />
            <p
              className="mb--30"
              dangerouslySetInnerHTML={{ __html: '5.2.2. Edad.' }}
            />
            <p
              className="mb--30"
              dangerouslySetInnerHTML={{ __html: '5.2.3. Correo electrónico.' }}
            />
            <p
              className="mb--30"
              dangerouslySetInnerHTML={{ __html: '5.2.4. Número celular.' }}
            />
            <p
              className="mb--50"
              dangerouslySetInnerHTML={{
                __html:
                  '5.3. Sin perjuicio de lo anterior, el Cliente deberá actualizar de manera oportuna y precisa toda la información necesaria para prestar los Servicios. El Cliente deberá notificar a Airbag por la inexactitud o error en la información proporcionada o ingresada, además de responder a cualquier solicitud de información adicional de Airbag. Airbag, a su entera discreción, podrá decidir no prestar los Servicios en caso de que no cuente con información suficiente para ello. Los errores u omisiones derivados de información inexacta, incompleta o errónea proporcionada por el Cliente, directamente o por cualquier Usuario, no generarán ningún tipo de responsabilidad para Airbag.'
              }}
            />

            <div className="breadcrumb-inner mb--50">
              <h2
                className="heading-section"
                dangerouslySetInnerHTML={{ __html: '6. Servicios de Terceros' }}
              />
            </div>

            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '6.1. El Cliente, los Administradores de Cuenta y los Usuario podrán recibir ofertas o contratar a través de Airbag servicios por parte de los socios de Airbag. Asimismo, para que Airbag pueda llevar a cabo la prestación de determinados Servicio, es necesario que el Cliente contrate y reciba servicios por parte de los socios de Airbag (cada uno de estos servicios, un “Servicio de Terceros”, y cada uno de dichos socios, un “Socio”).'
              }}
            />
            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '6.2. El Cliente, los Administradores de Cuenta y los Usuarios serán los únicos responsables y asumirán todos los riesgos derivados de o relacionados con la contratación de cualesquiera Servicio de Terceros, según corresponda. Airbag no será responsable de los Servicios de Terceros ni de cualquier material, información o resultados ofrecidos al Usuario a través de cualquier Servicio de Terceros.'
              }}
            />
            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '6.3. A este respecto, el Socio correspondiente podrá solicitar al Cliente, a los Administradores de Cuenta y a los Usuarios que acepten directamente los términos y condiciones o acuerdos específicos con respecto a la prestación de Servicios de Terceros.'
              }}
            />
            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '6.4. En caso de que el Cliente, el Administrador de Cuenta o el Usuario elijan recibir el Servicio de Terceros, autorizan a Airbag a enviar al Socio correspondiente todos los documentos e información sobre el Cliente o el Usuario (incluyendo la empresa del Cliente y de los operadores del Cliente) que sean necesarios para que dicho Socio le preste el Servicio de Tercero, incluida, entre otros, la información personal de los operadores del Cliente que haya entregado a Airbag en relación con estos Términos y Condiciones y la prestación de los Servicios al Cliente (conjuntamente, la “Información Compartida”), siendo el Cliente, el Administrador de Cuenta o el Usuario respectivo el responsable exclusivo de la exactitud y veracidad de la Información Compartida.'
              }}
            />
            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '6.5. Para efectos del presente apartado, se entiende que Airbag únicamente pone a disposición del Cliente, Administrador de Cuenta o Usuario, la Plataforma para la recepción de ofertas y, en su caso, la contratación de Servicios de Terceros, por lo que no será responsable y en ningún caso garantiza el contenido, calidad, estado, seguridad, idoneidad, integridad o legitimidad o legitimación con, o que de otro modo derive de la contratación de los Servicios de Terceros. En consecuencia, Airbag no será responsable de cualesquier daño, pérdida, menoscabo o perjuicio, que se ocasione por, de manera enunciativa mas no limitativa, cualquier reclamo, demanda, notificación, aviso citatorio, instrucción, orden, acción litigio o procedimiento relacionado con la calidad, integridad, legitimidad o cualquier otra causa análoga de los Servicios de Terceros.'
              }}
            />
            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '6.6. Debido a que Airbag es únicamente un intermediario y no tiene ninguna participación en la prestación de los Servicios de Terceros, en ningún caso será responsable del efectivo cumplimiento de las obligaciones asumidas entre el Socio y el Cliente, el Administrador de Cuenta o el Usuario con motivo de un Servicio de Tercero. El Cliente, el Administrador de Cuenta y el Usuario reconocen y aceptan que al contratar cualquier Servicio de Terceros lo hace bajo su propio riesgo y que Airbag no será responsable en ningún caso por cualquier daño o perjuicio que pudiera llegarse a ocasionar con motivo de la prestación de los Servicios de Terceros.'
              }}
            />
            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '6.7. De manera enunciativa mas no limitativa, Airbag no será responsable de cualquier daño o perjuicio que se derive de (i) la prestación de los Servicios de Terceros o cualquier consecuencia derivada de los mismos; (ii) cualquier acuerdo u operación entre el Cliente, el Administrador de Cuenta o el Usuario y el Socio que se origine con motivo de los Servicios de Terceros; o (iii) cualquier retraso, defecto o falta de ejecución de los Servicios de Terceros.'
              }}
            />
            <p
              className="mb--50"
              dangerouslySetInnerHTML={{
                __html:
                  '6.8. En virtud de lo anterior, el Socio será el único responsable frente al Cliente, al Administrador de Cuenta o al Usuario de cualquier daño o perjuicio que pudiera ocasionarse con motivo de los Servicios de Terceros, incluyendo, pero no limitado a, retrasos, falta de ejecución, defecto, calidad, integridad y legitimidad de los Servicios de Terceros. La acción del Cliente, del Administrador de Cuenta o del Usuario para reclamar la indemnización correspondiente del Socio se regirá según el plazo de prescripción y demás requisitos previstos en las disposiciones legales aplicables.'
              }}
            />

            <div className="breadcrumb-inner mb--50">
              <h2
                className="heading-section"
                dangerouslySetInnerHTML={{
                  __html: '7. Obligaciones del Cliente'
                }}
              />
            </div>

            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '7.1. El Cliente acepta no llevar a cabo, directamente o a través de cualquier Administrador de Cuenta o Usuario y procurará que éstos no lleven a cabo, cualquiera de los siguientes actos:'
              }}
            />

            <ul className="mb--50" style={{ listStyleType: `disc` }}>
              <li
                dangerouslySetInnerHTML={{
                  __html:
                    '7.1.1. Publicar, cargar, enviar, compartir, distribuir o transmitir cualquier contenido que: (i) no tenga facultades para publicar, cargar, enviar, compartir, distribuir o transmitir; (ii) infrinja, implique la apropiación indebida o viole cualquier patente, derecho de autor, marca, nombre comercial, secreto industrial, o cualesquiera otro derechos de propiedad intelectual de terceros; (iii) viole, o pudiera implicar cualquier conducta que pudiera violar, cualquier ley o regulación aplicable o que pudiera dar lugar a responsabilidad civil, penal o de cualquier otro tipo; (iv) sea fraudulento, falso o engañoso, difamatorio, indecente, obsceno, vulgar u ofensivo; (v) promueva la discriminación, intolerancia, racismo, odio, hostigamiento o daño contra cualquier individuo o grupo de individuos; (vi) sea violento o amenazante o promueva la violencia o actos que amenacen a cualquier persona o entidad; (vii) promueva actividades o sustancias ilegales o dañinas; o (viii) contenga virus, gusanos, defectos, troyanos, programas publicitarios, programas espía, malware u otros códigos, archivos o programas informáticos similares diseñados para interrumpir, destruir o limitar la funcionalidad de cualquier software o dispositivo de hardware;'
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html:
                    '7.1.2. Utilizar los Servicios de manera inconsistente o contraria a las disposiciones de los presente Términos y Condiciones o el Contrato de Prestación de Servicios;'
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html:
                    '7.1.3. Revender, otorgar sublicencias, compartir tiempo o compartir los Servicios con cualquier tercero;'
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html:
                    '7.1.4. Mostrar, reflejar o encuadrar (i) la Plataforma, o la distribución o diseño de cualquier página de la Plataforma o formulario incluido en la Plataforma; (ii) los Servicios; o (iii) cualquier elemento individual dentro de la Plataforma o los Servicios, incluido el nombre de Airbag y cualquier marca registrada, logotipo u otra información propiedad de Airbag, en cada caso, sin el consentimiento previo y por escrito de Airbag;'
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html:
                    '7.1.5. Acceder a, manipular o utilizar áreas no públicas de la Plataforma, los Servicios, los sistemas informáticos de Airbag o de los sistemas técnicos de entrega de los proveedores de Airbag;'
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html:
                    '7.1.6. Interferir o intentar interferir con el funcionamiento adecuado de la Plataforma o de los Servicios (incluidos, entre otros, cualquier aplicación, función o uso de los Servicios) o de cualquier actividad llevada a cabo en los Servicios;'
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html:
                    '7.1.7. Tomar cualquier medida que imponga o pueda imponer, según lo determine Airbag a su discreción absoluta, una carga excesiva o desproporcional sobre la infraestructura de Airbag o de sus Socios;'
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html:
                    '7.1.8. Utilizar software, dispositivos u otros procesos manuales o automatizados para realizar rastreos (crawls) o arañas (spiders) en cualquier página de la Plataforma;'
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html:
                    '7.1.9. Extraer (harvest) o rascar (scrape) cualquier contenido de la Plataforma o de los Servicios;'
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html:
                    '7.1.10. Intentar sondear, escanear o probar la vulnerabilidad de cualquier sistema o red de Airbag o violar cualquier medida de seguridad o autenticación;'
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html:
                    '7.1.11. Evitar, omitir, eliminar, desactivar, dañar, descifrar o evitar de cualquier forma cualquier medida tecnológica implementada por Airbag o cualquiera de los proveedores de Airbag o cualquier otro tercero (incluido otro Cliente) para proteger la Plataforma, los Servicios o el contenido de los mismos;'
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html:
                    '7.1.12. Intentar acceder o buscar en la Plataforma, los Servicios o el contenido de los mismos, o descargar el contenido de la Plataforma o los Servicios mediante el uso de cualquier motor, software, herramienta, agente, dispositivo o mecanismo (incluidas arañas (spiders), robots, rastreadores (crawlers), herramientas de minado de datos o similares), que no sean el software y/o agentes de búsqueda proporcionados por Airbag u otros navegadores web de terceros generalmente disponibles;'
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html:
                    '7.1.13. Acceder a los Servicios con el fin de monitorear su disponibilidad, rendimiento o funcionalidad, o para cualquier otra medida de evaluación comparativa (benchmarking) o con fines competitivos;'
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html:
                    '7.1.14. Enviar cualquier material publicitario, material promocional o correos electrónicos no solicitados o no autorizados, correos no deseados, correos basura, cadenas de cartas u otras formas de promoción a través de la Plataforma o los Servicios;'
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html:
                    '7.1.15. Usar cualquier “metaetiqueta” o cualquier otro texto o metadatos ocultos utilizando una marca registrada, logotipo, URL o nombre de producto de Airbag sin su consentimiento previo y por escrito;'
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html:
                    '7.1.16. Utilizar la Plataforma, los Servicios, o el contenido o cualquier parte de ellos, (i) para cualquier otro propósito que no sea la operación interna del Cliente, o (ii) para beneficio de un tercero o de cualquier manera que no está permitida en los presentes Términos y Condiciones;'
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html:
                    '7.1.17. Falsificar cualquier encabezado de paquete TCP/IP o cualquier parte de la información del encabezado en cualquier publicación de correo electrónico o grupo de noticias, o de alguna manera usar la Plataforma, los Servicios o el contenido de los mismos para enviar información con identificación de origen alterada, engañosa o falsa;'
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html:
                    '7.1.18. Intentar descifrar, descompilar, desensamblar, realizar ingeniería inversa o intentar derivar cualquier código fuente, ideas subyacentes o algoritmos de cualquiera de los programas utilizados para poner a su disposición la Plataforma o los Servicios o el contenido de los mismos;'
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html:
                    '7.1.19. Modificar, traducir o crear trabajos derivados de cualquier parte de la Plataforma o de los Servicios que no sea el contenido propio del Cliente;'
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html:
                    '7.1.20. Interferir con, o intentar interferir con, el acceso de cualquier Cliente, host o red, o usar cualquier dispositivo, software o rutina que pretenda dañar, interceptar subrepticiamente o expropiar cualquier sistema, datos o comunicación, incluyendo, entre otros, a través de enviar virus, sobrecargando, inundando, enviando spam o bombardeando por correo la Plataforma o los Servicios;'
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html:
                    '7.1.21. Recopilar ya sea de o en la Plataforma o los Servicios cualquier información de identificación personal o información protegida de otros Clientes;'
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html:
                    '7.1.22. Suplantar o falsear la relación del Cliente con cualquier persona o entidad;'
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html:
                    '7.1.23. Participar en prácticas o actividades fraudulentas, engañosas o ilegales, o utilizar los Servicios para apoyar directa o indirectamente tales prácticas o actividades; y'
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html:
                    '7.1.24. Alentar, asistir o permitir que cualquier otra persona lleve a cabo alguno o algunos de los actos anteriores.'
                }}
              />
            </ul>

            <div className="breadcrumb-inner mb--50">
              <h2
                className="heading-section"
                dangerouslySetInnerHTML={{
                  __html: '8. Vigencia y Terminación'
                }}
              />
            </div>

            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '8.1. Las disposiciones de los presentes Términos y Condiciones continuarán de manera indefinida.'
              }}
            />

            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html: '8.2. Sin perjuicio de lo anterior:'
              }}
            />

            <ul className="mb--30" style={{ listStyleType: `disc` }}>
              <li
                dangerouslySetInnerHTML={{
                  __html:
                    '8.2.1. Los presentes Términos y Condiciones se mantendrán vigentes mientras dure la prestación de cualquier Servicio y hasta en tanto se cumplan todas y cada una de las obligaciones previstas para dicho Servicio;'
                }}
              />
              <li
                dangerouslySetInnerHTML={{
                  __html:
                    '8.2.2. Airbag podrá en cualquier momento (i) suspender, bloquear, denegar o inhabilitar una Cuenta, el acceso a la Plataforma o a un determinado Servicio, o (ii) a solicitud del Cliente, suspender o limitar la capacidad de cualquier Usuario o Administrador de Cuenta para utilizar cualquier función en particular de algún Servicio.'
                }}
              />
            </ul>

            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '8.3. Asimismo, Airbag podrá en cualquier momento dar por terminado anticipadamente la prestación de cualquier Servicio, o suspender o limitar la prestación de cualquier Servicio, mediante una notificación por escrito entregada con al menos 30 (treinta) días naturales de anticipación al Cliente, en caso de que, a juicio de Airbag, (a) el Cliente, directamente o a través de cualquier Administrador de Cuenta o Usuario, incumpla con cualquiera de sus obligaciones al amparo de los presentes Términos y Condiciones (incluyendo el retraso en el pago de las tarifas por concepto de los Servicios y dicho incumplimiento no haya sido subsanado dentro de los 5 (cinco) días naturales siguientes ), (b) el Cliente, directamente o a través de cualquier Administrador de Cuenta o Usuario, utilice (o a juicio de Airbag, pueda utilizar) la Plataforma o los Servicios con el propósito de ejecutar actos dolosos, fraudulentos o ilícitos o de manera tal que quiera falsear la información o controles establecidos en la Plataforma, (c) Airbag tenga conocimiento del concurso o quiebra del Cliente, o (d) Airbag no pudiera verificar cualquier información proporcionada por el Cliente.'
              }}
            />

            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '8.4. El Cliente podrá dar por terminado en cualquier momento la prestación de los Servicios mediante una notificación por escrito al equipo de soporte de Airbag, con al menos 30 (treinta) días naturales de anticipación, en cuyo caso, previo a dicha terminación, deberá cubrir cualquier cantidad pendiente de pago en favor de Airbag.'
              }}
            />

            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '8.5. Airbag no tendrá responsabilidad alguna por los gastos, costos pérdidas, daños, penalizaciones o responsabilidades que se deriven o estén relacionadas con la terminación del Servicio.'
              }}
            />

            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '8.6. Tras la terminación de los presentes Términos y Condiciones, y sin perjuicio del derecho de Airbag de suspender, inhabilitar, o bloquear, total o parcialmente, el acceso a cualquier Cuenta en términos de los presentes Términos y Condiciones, se podrá acceder a las Cuentas con una capacidad limitada para revisar y descargar la información que estaba disponible en la Cuenta con respecto a los Servicios que ya hayan terminado de prestarse, durante un plazo máximo de 30 (treinta) días naturales siguientes a la terminación de los presentes Términos y Condiciones.'
              }}
            />

            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '8.7. Asimismo, el Cliente está de acuerdo en que, al término de los presentes Términos y Condiciones, Airbag podrá almacenar la información que haya sido proporcionada o ingresada por el Cliente en términos de los presentes Términos y Condiciones y sujeto a las obligaciones de confidencialidad que se detallan en el presente.'
              }}
            />

            <p
              className="mb--50"
              dangerouslySetInnerHTML={{
                __html:
                  '8.8. No obstante, la terminación de los presentes Términos y Condiciones, las obligaciones establecidas en las Secciones Limitación de Responsabilidad e Indemnización sobrevivirán por un plazo de 5 (cinco) años posteriores a la fecha de término de los mismos.'
              }}
            />

            <div className="breadcrumb-inner mb--50">
              <h2
                className="heading-section"
                dangerouslySetInnerHTML={{ __html: '9. Privacidad' }}
              />
            </div>

            <p
              className="mb--50"
              dangerouslySetInnerHTML={{
                __html:
                  "9.1. La recopilación y el uso de los datos personales que se origine en relación con los Servicios o los Servicios de Terceros se regirá conforme a lo establecido en el Aviso de Privacidad de Airbag, el cual se encuentra disponible en <a class='link-ap' href='https://airbagtech.io/privacy' target='_blank' rel='noopener noreferrer'> https://airbagtech.io/privacy</a> en cumplimiento a lo establecido por la Ley Federal de Protección de Datos Personales en Posesión de Particulares, su Reglamento, y demás disposiciones aplicables."
              }}
            />

            <div className="breadcrumb-inner mb--50">
              <h2
                className="heading-section"
                dangerouslySetInnerHTML={{
                  __html:
                    '10. Consentimiento para recibir Mensajes SMS/MMS/Whatsapp'
                }}
              />
            </div>

            <p
              className="mb--50"
              dangerouslySetInnerHTML={{
                __html:
                  '10.1. Al proporcionar el Usuario o el Administrador de Cuenta a Airbag su número de teléfono celular, éste acepta expresamente que Airbag podrá enviar mensajes a través del Servicio de Mensajes Cortos (“SMS”), Servicio de Mensajes Multimedia (“MMS”) o mensajes de WhatsApp (“Mensajes de Whatsapp”) sobre la actividad en su respectiva Cuenta y actualizaciones del servicio, así como también mensajes SMS, MMS o Mensajes de Whatsapp solicitando retroalimentación del Usuario con respecto a los Servicios y su experiencia al interactuar con el equipo de atención al cliente de Airbag, respecto de los cuales es posible que apliquen tarifas estándar de mensajes y datos. Asimismo, salvo que el Usuario o el Administrador de Cuenta manifieste lo contrario conforme a los procedimientos establecidos por Airbag, Airbag podrá enviarle mensajes de SMS, MMS o Mensajes de Whatsapp de marketing de marcación automática. En caso de que Usuario o el Administrador de Cuenta desee optar por no recibir mensajes SMS, MMS o Mensajes de Whatsapp, deberá comunicarse a Airbag en los términos aquí señalados.'
              }}
            />

            <div className="breadcrumb-inner mb--50">
              <h2
                className="heading-section"
                dangerouslySetInnerHTML={{
                  __html:
                    '11. Actualizaciones, Fallas en el Sistema, Sitios de Terceros y Fuerza Mayor'
                }}
              />
            </div>

            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '11.1. De tiempo en tiempo, Airbag podrá llevar a cabo mantenimiento programado o de emergencia de la Plataforma, con o sin previo aviso al Cliente, por lo que durante dichos periodos de mantenimiento la Plataforma podrá no estar accesible o disponible, sin ninguna responsabilidad para Airbag.'
              }}
            />

            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '11.2. Airbag no se responsabiliza por cualquier daño, perjuicio o pérdida al Cliente, directamente o a cualquier Administrador de Cuenta o Usuario, que pudiera ocasionarse por fallas en la Plataforma, en el servidor o en Internet (incluyendo cualquier tipo de accidente mientras se utilice la Plataforma o los Servicios). Airbag tampoco será responsable por cualquier virus que pudiera infectar el equipo de Cliente como consecuencia del acceso o uso a la Plataforma o a raíz de cualquier transferencia de datos, archivos, imágenes, textos o audio contenidos en la misma. El Cliente no podrá exigirle responsabilidad alguna a Airbag, ni podrá exigir el pago de ninguna cantidad por concepto de daño, perjuicio o pérdida resultante de dificultades técnicas o fallas en la Plataforma o en Internet. Airbag no garantiza el acceso y uso continuado o ininterrumpido a la Plataforma, debido a que éste puede eventualmente no estar disponible debido a dificultades técnicas, fallas de Internet o cualquier otra causa ajena a Airbag; en tales casos se procurará restablecerlo con la mayor celeridad posible, sin que por ello pueda imputársele algún tipo de responsabilidad a Airbag. Asimismo, puesto que la Plataforma depende de productos y servicios de proveedores externos de tecnología, tales como interfaces de programación y servicios de hospedaje web, Airbag no garantiza ni se responsabiliza por los cambios en cualquiera de estos proveedores externos que puedan afectar de manera adversa a la Plataforma.'
              }}
            />

            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '11.3. Asimismo, Airbag no será responsable por retrasos o incumplimientos derivados de caso fortuito o fuerza mayor, como lo son desastres naturales, guerras, actos terroristas, pandemias, disturbios generalizados, incendios, terremotos, inundaciones, cortes de energía generales, huelgas, condiciones climáticas adversas, actos de piratas informáticos, actos de proveedores de servicios de Internet.'
              }}
            />

            <p
              className="mb--50"
              dangerouslySetInnerHTML={{
                __html:
                  '11.4. La Plataforma puede contener publicidad y/o enlaces a otros sitios web de terceros. En dichos casos, Airbag no será responsable de ninguna manera del contenido, productos, servicios, anuncios u otra información que pueda ser proporcionado por o a través de tales sitios o publicidad, ni de sus políticas de privacidad o su cumplimiento con las disposiciones legales aplicables.'
              }}
            />

            <div className="breadcrumb-inner mb--50">
              <h2
                className="heading-section"
                dangerouslySetInnerHTML={{
                  __html: '12. Limitaciones de Responsabilidad'
                }}
              />
            </div>

            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '12.1. El uso o acceso a la Plataforma, la contratación de los Servicios por parte del Cliente es bajo su propio riesgo, por lo que en ningún caso deberá entenderse que Airbag presta cualquier de asesoría profesional de cualquier tipo y en ningún caso Airbag será responsable en caso de cualquier accidente que se ocasione mientras se utilicen la Plataforma o los Servicios.'
              }}
            />

            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '12.2. En la máxima medida permitida por la legislación aplicable, la Plataforma y los Servicios se proporcionan “tal cual” (en inglés, “as is”), sin garantía adicional de ningún tipo a lo expresamente establecido en la Plataforma o los Servicios, según corresponda, por parte de Airbag.'
              }}
            />

            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '12.3. En caso de que se produzca algún daño, menoscabo, pérdida o perjuicio debido al retraso en la entrega, error, inexactitud o falsedad, ya sea directa o indirectamente, en la información (o modificaciones a la información) que haya sido proporcionada, directa o indirectamente, por el Cliente, Airbag no será responsable de los mismos, incluyendo, de manera enunciativa mas no limitativa, cualquier reclamo, demanda, notificación, aviso, citatorio, instrucción, orden, acción, litigio o procedimiento, derivado de, a raíz de, o que de cualquier otra forma se relacione, directa o indirectamente, con dicho daño, menoscabo, pérdida o perjuicio.'
              }}
            />

            <p
              className="mb--50"
              dangerouslySetInnerHTML={{
                __html:
                  '12.4. EN NINGÚN CASO LA RESPONSABILIDAD DE AIRBAG POR CUALQUIER RECLAMACIÓN NO EXCLUIDA PREVIAMENTE Y QUE SE DERIVE O RELACIONE CON LOS PRESENTES TÉRMINOS Y CONDICIONES, INCLUYENDO AQUELLAS RELACIONADAS CON DAÑOS CONSECUENCIALES O INDIRECTOS PERMITIDOS, O CON EL USO O INCAPACIDAD DE USAR LA PLATAFORMA O LOS SERVICIOS EXCEDERÁ LOS MONTOS QUE EL CLIENTE HAYA PAGADO A AIRBAG POR EL USO DE LA PLATAFORMA O LOS SERVICIOS EN EL PERIODO DE 6 (SEIS) MESES INMEDIATAMENTE ANTERIOR A LAS CAUSALES QUE DEN LUGAR A LA RECLAMACIÓN CORRESPONDIENTE. LAS EXCLUSIONES Y LIMITACIONES DE DAÑOS Y PERJUICIOS AQUÍ ESTABLECIDOS FORMAN PARTE DEL MOTIVO DETERMINANTE DE LA VOLUNTAD DE AIRBAG PARA LA CELEBRACIÓN DE LOS PRESENTES TÉRMINOS Y CONDICONES Y EL CONTRATO DE PRESTACIÓN DE SERVICIOS CON EL CLIENTE.'
              }}
            />

            <div className="breadcrumb-inner mb--50">
              <h2
                className="heading-section"
                dangerouslySetInnerHTML={{ __html: '13. Indemnización' }}
              />
            </div>

            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '13.1. El Cliente acuerda y se obliga a indemnizar y a sacar en paz y a salvo a Airbag (incluyendo, pero no limitado a, sus respectivos accionistas, directores, gerentes, funcionarios, representantes agentes y empleados) (las “Partes Indemnizadas”) de cualquier reclamación, controversia, demanda, juicio, responsabilidad, sanción, multa daño, pérdida, gasto o costo (conjuntamente, las “Reclamaciones”), que pudiera hacerse en su contra con motivo o en relación con (i) el acceso o uso de la Plataforma o los Servicios por parte de cualquiera de sus Administradores de Cuenta o Usuarios, (ii) el uso de la Cuenta (incluyendo cualquier acto doloso, fraudulento o ilícito), (iii) el retraso en la entrega, error, inexactitud o falsedad, ya sea directa o indirectamente, en la información (o modificaciones a la información) que haya sido proporcionada por el Cliente, directamente o a través de cualquier Administrador de Cuenta o Usuario, (iv) la violación o supuesta violación a cualquier derecho de propiedad intelectual de Airbag o de terceros, (v) el incumplimiento o violación de cualesquiera leyes, normas o regulaciones aplicables a las Partes Indemnizadas o al Cliente, directamente o a través de cualquier Administrador de Cuenta o Usuario, o (vi) cualquier incumplimiento a los Términos y Condiciones, el Contrato de Prestación de Servicios o a cualquier política de Airbag, por parte del Cliente, directamente o a través de cualquier Administrador de Cuenta o Usuario.'
              }}
            />

            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '13.2. En razón de lo anterior, el Cliente acuerda indemnizar y eximir de responsabilidad las Partes Indemnizadas por cualquier Reclamación, obligándose a resarcir a Airbag de cualquier cantidad que erogare por dichos conceptos (incluyendo, cualesquiera costos y honorarios legales y contables).'
              }}
            />

            <p
              className="mb--50"
              dangerouslySetInnerHTML={{
                __html:
                  '13.3. En caso de que el Cliente, directamente o a través cualquier Usuario o Administrador de Cuenta, tenga conocimiento de alguna Reclamación en contra de alguna Parte Indemnizada (o que razonablemente pudiera interponerse en contra de alguna Parte Indemnizada) deberá informarlo inmediatamente por escrito a Airbag, y deberá tomar todas las medidas necesarias o convenientes para mitigar cualquier Reclamación en contra de las Partes Indemnizadas. Lo anterior en el entendido de que dicha notificación no eximirá al Cliente de sus obligaciones de indemnización conforme a lo establecido en los presentes Términos y Condiciones.'
              }}
            />

            <div className="breadcrumb-inner mb--50">
              <h2
                className="heading-section"
                dangerouslySetInnerHTML={{ __html: '14. Relación Comercial' }}
              />
            </div>

            <p
              className="mb--50"
              dangerouslySetInnerHTML={{
                __html:
                  '14.1. Ninguna disposición contemplada en estos Términos y Condiciones constituye de manera alguna cualquier tipo de sociedad, asociación, comisión mercantil o relación laboral entre Airbag y el Cliente, el Administrador de Cuenta o el Usuario.'
              }}
            />

            <div className="breadcrumb-inner mb--50">
              <h2
                className="heading-section"
                dangerouslySetInnerHTML={{ __html: '15. Propiedad Industrial' }}
              />
            </div>

            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '15.1. La Plataforma y todos los derechos relativos a la misma son propiedad exclusiva de Airbag. Como parte de la prestación de los Servicios, Airbag otorga al Cliente una licencia no exclusiva, personal e intransferible, sobre su respectiva Plataforma, en relación con el uso permitido por la misma por parte del Cliente de conformidad con el Contrato de Prestación de Servicios y los presentes Términos y Condiciones. Sin perjuicio de lo anterior, ninguna disposición prevista en presentes Términos y Condiciones, ni el uso de la Plataforma otorgan o transfieren ningún derecho de autor o derecho de propiedad industrial adicional sobre o en relación con la Plataforma. Cualquier plantilla, documento o material que Airbag proporcione al Cliente a través de los Servicios será de exclusiva propiedad de Airbag.'
              }}
            />

            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '15.2. El Cliente está de acuerdo en no retirar, alterar u ocultar cualquier derecho de propiedad industrial o de autor de Airbag. Asimismo, el Cliente no deberá utilizar la Plataforma de cualquier forma que sea inconsistente con los presentes Términos y Condiciones o el Contrato de Prestación de Servicios (incluyendo, el otorgamiento de sublicencias o uso por parte de terceros, distintos de los Administradores de Cuenta o Usuarios del Cliente).'
              }}
            />

            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '15.3. La información que sea proporcionada, entregada o publicada a través de la Plataforma será considerada propiedad del Cliente, en consecuencia, el Cliente declara y garantiza que es titular de toda la información que sea proporcionada, entregada o publicada a través de la Plataforma o, en su caso, que cuenta con todos los derechos y autorizaciones necesarias para proporcionar, entregar o publicar dicha información. De forma general el Cliente podrá retirar la información que haya proporcionado en la Plataforma (salvo que dicha información sea necesaria para la prestación del Servicio, o que se indique de otra manera en la Plataforma). No obstante, en ciertos casos, es posible que la información del Cliente continúe existiendo en la Plataforma. Airbag no es responsable del retiro o eliminación de (o la falta de retirar o eliminar) cualquier contenido del Cliente; asimismo Airbag tendrá derecho, en todo momento y sin previo aviso, de retirar de la Plataforma cualquier contenido del cliente si considera que el mismo viola los presentes Términos y Condiciones o la legislación aplicable.'
              }}
            />

            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '15.4. Al proporcionar, entregar o publicitar el Cliente información a Airbag a través de la Plataforma, éste otorga a Airbag una licencia no exclusiva, indefinida, transferible, susceptible de ser sublicenciada, mundial y libre de regalías para usar, copiar, modificar, crear obras derivadas, mostrar públicamente, realizar públicamente y distribuir dicha información en relación con la operación y la prestación de los Servicios y la Plataforma, declarando el Cliente que cuenta con todos los derechos y autorizaciones necesarias para otorgar dicha licencia.'
              }}
            />

            <p
              className="mb--50"
              dangerouslySetInnerHTML={{
                __html:
                  '15.5. Cualquier comentario, retroalimentación o sugerencia (incluyendo cualquier tipo de encuesta con respecto a la experiencia del Cliente, o de sus Administradores de Cuenta o Usuarios) (la “Retroalimentación”) que el Cliente pueda proporcionar en relación con o para mejorar la Plataforma o los Servicios se proporcionará de forma totalmente voluntaria, y Airbag será libre de utilizar, divulgar, reproducir o distribuir de cualquier manera y explotar tal Retroalimentación como lo considere pertinente, sin ningún tipo de restricción.'
              }}
            />

            <div className="breadcrumb-inner mb--50">
              <h2
                className="heading-section"
                dangerouslySetInnerHTML={{ __html: '16. Confidencialidad' }}
              />
            </div>

            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '16.1. Toda la información que se relacione con la información proporcionada por el Cliente será tratada como información confidencial y no podrá ser revelada sin el consentimiento del Cliente, excepto a aquellos funcionarios, empleados, representantes o asesores de Airbag que tengan necesidad de conocer la información para el cumplimiento de las obligaciones conforme a los presentes Términos y Condiciones, así como a los Socios de Airbag, o de cualquier otra manera prevista en los presentes Términos y Condiciones. No obstante, Airbag podrá revelar la información de conformidad con requerimientos gubernamentales, administrativos o judiciales a los cuales esté sujeto, previo aviso al Cliente y, siempre y cuando la revelación de la información sea obligatoria para Airbag de conformidad con las leyes aplicables o con una orden de autoridad. Esta obligación de confidencialidad tendrá una duración que concluirá a los 12 (doce) meses siguientes a la fecha de terminación de estos Términos y Condiciones y no cesará con motivo de la expiración, suspensión, terminación o rescisión de los presentes Términos y Condiciones Generales.'
              }}
            />

            <p
              className="mb--50"
              dangerouslySetInnerHTML={{
                __html:
                  '16.2. Airbag no está obligada, pero tiene derecho a monitorear el acceso y uso del Cliente, y sus respectivos Administradores de Cuenta y Usuarios, a la Plataforma y los Servicios, para fines de operación de la misma, así como para investigar conductas que considere que podrían afectar la Plataforma o los Servicios, incluyendo las posibles violaciones a estos Términos y Condiciones o la ley aplicable.'
              }}
            />

            <div className="breadcrumb-inner mb--50">
              <h2
                className="heading-section"
                dangerouslySetInnerHTML={{ __html: '17. Modificaciones' }}
              />
            </div>

            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '17.1. Airbag podrá modificar los presente Términos y Condiciones en cualquier momento, sin necesidad de notificarlo previamente al Cliente. Sin perjuicio de lo anterior, Airbag podrá publicar las actualizaciones o modificaciones a los Términos y Condiciones en la Plataforma, en el entendido de que la sola utilización de la Plataforma implica la aceptación expresa por parte del Cliente, del Administrador de Cuenta o del Usuario de cualquier actualización o modificación de los Términos y Condiciones.'
              }}
            />

            <p
              className="mb--50"
              dangerouslySetInnerHTML={{
                __html:
                  '17.2. La última actualización de los Términos y Condiciones Generales ocurrió el [●] de noviembre de 2021.'
              }}
            />

            <div className="breadcrumb-inner mb--50">
              <h2
                className="heading-section"
                dangerouslySetInnerHTML={{ __html: '18. Encabezados' }}
              />
            </div>

            <p
              className="mb--50"
              dangerouslySetInnerHTML={{
                __html:
                  '18.1. El Cliente reconoce que los encabezados de cada una de las cláusulas de los presentes Términos y Condiciones son únicamente para fines de referencia y no afectarán el significado o interpretación de los mismos.'
              }}
            />

            <div className="breadcrumb-inner mb--50">
              <h2
                className="heading-section"
                dangerouslySetInnerHTML={{
                  __html:
                    '19. Independencia de Cláusulas, Acuerdo Total y Cesiones'
                }}
              />
            </div>

            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '19.1. Los presentes Términos y Condiciones y el Contrato de Prestación de Servicios celebrado entre el Cliente y Airbag constituyen la integridad del acuerdo entre Airbag y el Cliente con respecto a la Plataforma y los Servicios, por lo que reemplazan todos los entendimientos, comunicaciones y acuerdos anteriores, verbales o escritos, previos.'
              }}
            />

            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '19.2. En caso de que cualquier parte de estos Términos y Condiciones se considere inaplicable o inválida, dicha sección o cláusula se eliminará sin afectar el resto de estos Términos y Condiciones, por lo que los términos restantes serán válidos y aplicables.'
              }}
            />

            <p
              className="mb--50"
              dangerouslySetInnerHTML={{
                __html:
                  '19.3. El Cliente no podrá ceder estos Términos y Condiciones, ni cualquier derecho u obligación derivado de los Servicios, sin el consentimiento previo y por escrito de Airbag. Airbag podrá ceder o transferir libremente estos Términos y Condiciones bastando para ello una notificación por escrito al Cliente. Las disposiciones de los presentes Términos y Condiciones resultarán en beneficio de y serán vinculantes para las partes que en él intervienen, así como para sus respectivos herederos, sucesores y cesionarios permitidos.'
              }}
            />

            <div className="breadcrumb-inner mb--50">
              <h2
                className="heading-section"
                dangerouslySetInnerHTML={{
                  __html: '20. Domicilios y Notificaciones'
                }}
              />
            </div>

            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '20.1. El domicilio de Airbag se encuentra ubicado en: José María Morelos 155, San Jerónimo Lídice, La Magdalena Contreras, C.P. 10200, Ciudad de México, correo electrónico admin@airbagtech.io'
              }}
            />

            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '20.2. El domicilio del Cliente será aquél que se detalle en el Contrato de Prestación de Servicios.'
              }}
            />

            <p
              className="mb--50"
              dangerouslySetInnerHTML={{
                __html:
                  '20.3. Cualquier notificación u otras comunicaciones emitidas por Airbag en virtud de estos Términos y Condiciones, incluidas las relacionadas con modificaciones estos Términos y Condiciones, se entregarán: (i) por correo electrónico; o (ii) mediante su publicación en la Plataforma.'
              }}
            />

            <div className="breadcrumb-inner mb--50">
              <h2
                className="heading-section"
                dangerouslySetInnerHTML={{
                  __html: '21. Ley Aplicable y Arbitraje'
                }}
              />
            </div>

            <p
              className="mb--30"
              dangerouslySetInnerHTML={{
                __html:
                  '21.1. Los presentes Términos y Condiciones y el Contrato de Prestación de Servicios se regirán e interpretarán conforme a las leyes de la Ciudad de México, Estados Unidos Mexicanos. Todas las desavenencias que deriven de los presentes Términos y Condiciones y el Contrato de Prestación de Servicios, incluyendo su incumplimiento, resolución o nulidad, serán resueltas definitivamente de acuerdo con las Reglas de Arbitraje del Centro de Arbitraje de México (CAM), por un solo árbitro nombrado conforme a dichas Reglas. La sede del arbitraje será la Ciudad de México y el idioma será español.'
              }}
            />
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TerminosCondicionesC;
