import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import Copyright from 'components/footer/Copyright';
import data from 'data/animation/footer.json';
import logo from '../../assets/img/logo/airbag-logo-dark-dot-w.svg';
import Logo from 'components/header/elements/logo';
import Widget from 'components/widget';
import socials from '../../data/socials';
import { toCapitalize } from '../../utils/toCapitalize';

import gsap from 'gsap';

import { useTranslation } from 'react-i18next';

const FooterOne = ({ className }) => {
  const { t } = useTranslation();

  useEffect(() => {
    gsap.set('.box', {
      x: (i) => i * 150
    });

    gsap.to('.box', {
      duration: 20,
      ease: 'none',
      x: '+=1500',
      modifiers: {
        x: gsap.utils.unitize((x) => parseFloat(x) % 1500)
      },
      repeat: -1
    });
  }, []);

  return (
    <footer
      className={`page-footer pl--150 pr--150 pl_lg--30 pr_lg--30 pl_md--30 pr_md--30 pl_sm--5 pr_sm--5 before-panel ${className}`}
    >
      <div className="bk-footer-inner pt--30 pb--30 pt_sm--100">
        <Container fluid="sm" className="airbag-footer">
          <Row>
            <Col className="mt_mobile--40">
              <Widget wrapClasses="text-var--2" title={null}>
                <Logo logo={logo} />
              </Widget>
              <div className="copyright pt--20 text-var-2">
                <div className="copyright-right bk-hover">
                  <Copyright />
                </div>
              </div>

              <ul className="social-icon style-solid-rounded-icon icon-size-medium">
                {socials.map((icon) => (
                  <li key={icon.id} className={icon.media}>
                    <a
                      href={`https://${icon.media}.com/${icon.username}`}
                      className="link hint--bounce hint--top hint--white"
                      aria-label={toCapitalize(icon.media)}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <i className={`fa fa-${icon.media}`} />
                    </a>
                  </li>
                ))}
              </ul>
            </Col>

            <Col className="mt_mobile--40">
              <Widget
                wrapClasses="text-var--2 menu--about"
                title={t('footer_category_one')}
              >
                <div className="footer-menu">
                  <ul className="ft-menu-list bk-hover">
                    <li>
                      <Link to={process.env.PUBLIC_URL + '/app'}>App</Link>
                    </li>
                    <li>
                      <Link to={process.env.PUBLIC_URL + '/logistics'}>
                        DMS
                      </Link>
                    </li>
                    <li>
                      <Link to={process.env.PUBLIC_URL + '/x-ray'}>X-ray</Link>
                    </li>
                  </ul>
                </div>
              </Widget>
            </Col>

            <Col className="mt_mobile--40">
              <Widget
                wrapClasses="text-var--2 menu--about"
                title={t('footer_category_two')}
              >
                <div className="footer-menu">
                  <ul className="ft-menu-list bk-hover">
                    <li>
                      <a
                        href="https://blog.airbagtech.io"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {t('nav_item_four')}
                      </a>
                    </li>
                    <li>
                      <Link to={process.env.PUBLIC_URL + '/testimonios'}>
                        {t('nav_item_two_one')}
                      </Link>
                    </li>
                    <li>
                      <Link to={process.env.PUBLIC_URL + '/equipo'}>
                        {t('nav_item_three_one')}
                      </Link>
                    </li>
                  </ul>
                </div>
              </Widget>
            </Col>

            <Col className="mt_mobile--40">
              <Widget
                wrapClasses="text-var--2 menu--about"
                title={t('footer_category_three')}
              >
                <div className="footer-menu">
                  <ul className="ft-menu-list bk-hover">
                    <li>
                      <Link to={process.env.PUBLIC_URL + '/terms'}>
                        {t('footer_item_one')}
                      </Link>
                    </li>
                    <li>
                      <Link to={process.env.PUBLIC_URL + '/privacy'}>
                        {t('footer_item_two')}
                      </Link>
                    </li>
                  </ul>
                </div>
              </Widget>
            </Col>
          </Row>

          <div className="wrapper-boxes mt--40">
            <div className="boxes">
              {data.map((car, index) => (
                <div className="box" key={index}>
                  <img
                    src={require('assets/img/animation/' + car.image)}
                    alt={car.title}
                  />
                </div>
              ))}
            </div>
          </div>
        </Container>
      </div>
    </footer>
  );
};

FooterOne.defaultProps = {
  className: 'bg_color--3'
};

export default FooterOne;
