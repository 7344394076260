import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Button from 'components/button';
import heroImage from 'assets/img/revolution/hero-service-3-home.svg';
import heroVideo from 'assets/img/revolution/Web_airbag_Animacion_03_Gris_Home.mp4';

import { useTranslation } from 'react-i18next';

const HeroHome = () => {
  const { t } = useTranslation();

  return (
    <div className="hero-banner-wrapper bg_color--26">
      <Container fluid="sm">
        <Row>
          <Col xs={12} className="text-center">
            <div className="hero-banner-content">
              {/* <img src={heroImage} alt="mouse icon" /> */}

              <video
                className="hero-home-web"
                playsInline
                autoPlay="autoplay"
                loop
                muted
                poster={heroImage}
              >
                <source src={heroVideo} type="video/mp4" />
              </video>

              <h2
                className="hero-home-title"
                dangerouslySetInnerHTML={{ __html: t('home_hero_title') }}
              />

              <p
                dangerouslySetInnerHTML={{ __html: t('home_hero_description') }}
              />

              <Button
                type={'link'}
                btnText={t('button_three')}
                btnLink={'/app'}
                arrow={false}
                size={'sd'}
                color={'theme'}
                btnStyle={'bullet'}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HeroHome;
