import React from 'react';

const Copyright = ({ className }) => {
  return (
    <p className={className ? className : ''}>
      Airbag Technologies ® {new Date().getFullYear()}. CDMX, México.{' '}
      <a
        href="mailto:contacto@airbagtech.io"
        target="_blank"
        rel="noopener noreferrer"
      >
        contacto@airbagtech.io
      </a>
    </p>
  );
};

export default Copyright;
