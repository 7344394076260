import React from 'react';

import { useTranslation } from 'react-i18next';

const NewsCardOne = (props) => {
  // Thumb Size: 300 x 300
  // Description: 100 charc or less
  const { thumb, title, description, date, relink, source } = props;
  const { t } = useTranslation();

  return (
    <div className="new-card-container" onClick={() => window.open(relink)}>
      <div
        className="new-card-image"
        style={{
          backgroundImage: `url(${require('assets/img/news/' + thumb)})`
        }}
      />
      <div className="new-card-title">
        <h4>{title}</h4>
      </div>
      <div className="new-card-content">
        <p
          className="new-card-read"
          dangerouslySetInnerHTML={{
            __html: t(`${description}`)
          }}
        />
        <p className="new-card-source">- {source}</p>
      </div>
      <div className="new-card-footer">
        <p className="new-card-date">{date}</p>
        <p
          className="new-card-read"
          dangerouslySetInnerHTML={{
            __html: t('news_read_more')
          }}
        />
      </div>
    </div>
  );
};
export default NewsCardOne;
