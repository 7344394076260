import React, { Fragment } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import brook from 'data/brook.json';

import Footer from 'container/footer/FooterOne';
import Awards from 'container/awards/homes/Awards';
import LayoutDefault from 'layouts/LayoutDefault';
import Header from 'components/header/Header';

const Quotation = () => {
  const { i18n } = useTranslation();

  return (
    <Fragment>
      <Helmet>
        <title>{`Cotizacion | ${brook.title}`}</title>
      </Helmet>
      <LayoutDefault className="template-color-4 template-font-1">
        <Header />
        <div className="section-pt-xl">
          {i18n && i18n.language === 'es' ? (
            <iframe
              src="https://forms.monday.com/forms/embed/aa811b20363b2bb00d31869d44852f1d?r=use1"
              width="100%"
              height="1000"
              style={{ border: 'none' }}
            ></iframe>
          ) : (
            <iframe
              src="https://forms.monday.com/forms/embed/1ae5e49546b3a476f2d629d2ee769efe?r=use1"
              width="100%"
              height="1000"
              style={{ border: 'none' }}
            ></iframe>
          )}
        </div>
        <Awards bgColor={'black'} />
      </LayoutDefault>
      <Footer className="bg_color--3" />
    </Fragment>
  );
};

export default Quotation;
