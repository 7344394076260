import React from 'react';
import { Link } from 'react-router-dom';
import { ReactComponent as Default } from 'assets/img/logo/airbag-logo-dark.svg';

const Logo = ({ logo }) => {
  return (
    <div className="logo">
      <Link to={process.env.PUBLIC_URL + '/'}>
        {logo ? <img src={logo} alt="logo" /> : <Default />}
      </Link>
    </div>
  );
};

export default Logo;
