import React from 'react';
import { useTranslation } from 'react-i18next';

const DemoRequestSection = () => {
  const { i18n } = useTranslation();

  return (
    <div className="section-pt-xl">
      {i18n && i18n.language === 'es' ? (
        <iframe
          title="airbag-form"
          id="trial-form"
          src="https://forms.monday.com/forms/embed/577ecf94d30e7d700a9beca7d937f5ad?r=use1"
          width="100%"
          height="1400"
          style={{ border: 'none' }}
        ></iframe>
      ) : (
        <iframe
          title="airbag-form"
          id="trial-form"
          src="https://forms.monday.com/forms/embed/39d20c18e5693255a1c285ea833aecba?r=use1"
          width="100%"
          height="1400"
          style={{ border: 'none' }}
        ></iframe>
      )}
    </div>
  );
};

export default DemoRequestSection;
