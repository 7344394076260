import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import Thumbnail from 'components/about-us/thumbnail/AboutThumbOne';
import errorImg from 'assets/img/icons/image_404.png';
import Button from 'components/button';

const Error404 = () => {
  return (
    <div className="error-not-found">
      <Container fluid="sm">
        <Row>
          <Col xs={12}>
            <div className="error-inner text-center d-flex flex-column">
              <Thumbnail
                thumb={errorImg}
                className="mb--55 image text-center"
              />
              <h3 className="heading heading-h3 text-white">
                Parece que estás perdido.
              </h3>
              <div className="error-text mt--20">
                <p className="text-white">
                  Parece que no se encontró nada en este lugar. Puedes volver a
                  la última página o ir a la página de inicio.
                </p>

                <div className="error-button-group mt--40 d-flex justify-content-center align-items-center">
                  <div className="mr--40 return-button">
                    <Button
                      type="button"
                      btnText="Regresar"
                      btnStyle="bullet"
                      size="sd"
                      color="theme"
                      arrow={false}
                      onClick={() => window.history.back()}
                    />
                  </div>

                  <Button
                    type="link"
                    btnText="Homepage"
                    btnLink="/"
                    arrow={false}
                    btnStyle="bullet"
                    size="sd"
                    color="theme"
                  />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Error404;
