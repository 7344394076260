import React from 'react';
import Logo from './elements/logo';
import Navbar from './elements/navbar/Navbar';
import HeaderContainer from 'container/header/HeaderContainerOne';
import NavbarButton from 'components/header/elements/button/NavbarButton';

const Header = () => {
  return (
    <HeaderContainer
      logoVersion={'dark'}
      transparent={true}
      sticky={true}
      classes="position-from--top"
    >
      <div className="header-left">
        <Logo />
      </div>

      <Navbar />

      <div className="header-right flex-20">
        <NavbarButton />
      </div>
    </HeaderContainer>
  );
};

export default Header;
