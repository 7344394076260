import React from 'react';
import { NavLink } from 'react-router-dom';
// import {Link} from "react-router-dom";
import navbarData from 'data/navbar';

import { useTranslation } from 'react-i18next';

const NavbarItem = () => {
  const { t } = useTranslation();

  function renderMainLink(navbar) {
    if (navbar.subMenu) {
      return (
        <NavLink
          to={`${process.env.PUBLIC_URL + navbar.link}`}
          id="subMenu-indicator"
          activeClassName="subMenu-active"
        >
          <span>{t(navbar.title)}</span>
        </NavLink>
      );
    } else {
      if (navbar.externalLink) {
        return (
          <a href={navbar.link} target="_blank" rel="noopener noreferrer">
            <span>{t(navbar.title)}</span>
          </a>
        );
      } else {
        return (
          <NavLink
            exact
            to={`${process.env.PUBLIC_URL + navbar.link}`}
            activeClassName="active"
          >
            <span>{t(navbar.title)}</span>
          </NavLink>
        );
      }
    }
  }

  return navbarData.map((navbar) => (
    <li
      key={navbar.id}
      className={`label-1 ${
        navbar.megaMenu || navbar.subMenu ? 'with--drop' : ''
      } ${navbar.megaMenu ? 'slide--megamenu' : ''} ${
        navbar.subMenu ? 'slide-dropdown' : ''
      }`}
    >
      {renderMainLink(navbar)}

      {navbar.subMenu ? (
        <ul className="dropdown__menu">
          {navbar.subMenu.lists.map((subItem) => (
            <li key={subItem.id}>
              <NavLink
                exact
                to={`${process.env.PUBLIC_URL + subItem.link}`}
                id="submenu-element"
                activeClassName="active"
              >
                <span>{t(subItem.title)}</span>
              </NavLink>
            </li>
          ))}
        </ul>
      ) : null}
      {navbar.megaMenu ? (
        <div className="mega__width--fullscreen">
          <div className="container">
            <div className="row">
              {navbar.megaMenu.map((megaItem) => (
                <div key={megaItem.id} className="col-lg-3">
                  <ul className="mega__list">
                    <li className="mega--title">{megaItem.megaTitle}</li>

                    {megaItem.lists.map((list) => (
                      <li key={list.id}>
                        <NavLink
                          exact
                          to={`${process.env.PUBLIC_URL + list.link}`}
                          activeClassName="active"
                        >
                          <span>{list.title}</span>
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </div>
        </div>
      ) : null}
    </li>
  ));
};

export default NavbarItem;
