import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SlickSlider from 'components/slick';
import ServiceItemTwo from 'components/service/ServiceItemTwo';
import servicesData from 'data/service/ServiceDigitalAgency.json';
import { useTranslation } from 'react-i18next';

const Awards = ({ bgColor, bgImage }) => {
  const { t } = useTranslation();
  const settings = {
    speed: 5000,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    swipeToSlide: true,
    centerMode: true,
    focusOnSelect: false,
    arrows: false,
    dots: false,
    responsive: [
      {
        breakpoint: 801,
        settings: {
          slidesToShow: 2
        }
      },
      {
        breakpoint: 577,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  return (
    <div
      className="brook-our-awards section-ptb-xl before-panel"
      style={{ backgroundColor: bgColor, backgroundImage: `url(${bgImage})` }}
    >
      <Container fluid="sm">
        <Row className="align-items-center">
          <Col xs={12}>
            <div className="brook-section-title awards text-right">
              <h1
                dangerouslySetInnerHTML={{ __html: t('footer_logos_title') }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={12} className="mt--80">
            <SlickSlider
              settings={settings}
              classes="cars-road brook-element-carousel slick-arrow-center arrow-transparent slick-gutter-15"
            >
              {servicesData.map((service) => (
                <ServiceItemTwo key={service.id} data={service} />
              ))}
            </SlickSlider>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default Awards;
