import { Helmet } from 'react-helmet';
import React, { Fragment, useEffect, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import Widget from 'components/widget';
import imagesLoaded from 'imagesloaded';
import heroImageTwo from 'assets/img/revolution/hero-service-2.svg';
import heroVideoTwo from 'assets/img/revolution/Web_airbag_Animacion_02_Amarillo.mp4';
import LayoutDefault from 'layouts/LayoutDefault';
import collage from 'assets/img/testimonial/testimonios-collage.png';
import SlickSlider from 'components/slick';
import heroImageHero3 from 'assets/img/revolution/hero-service-3-home.svg';
import heroVideoHero3 from 'assets/img/revolution/Web_airbag_Animacion_03_Gris_Home.mp4';
import imageOne from 'assets/img/service/funfact-illustration-1.svg';
import imageTwo from 'assets/img/service/service-2-illustration-2.svg';
import imageThree from 'assets/img/service/crash.svg';
import imageFour from 'assets/img/service/bus.svg';
import imageEight from 'assets/img/service/service-1-illustration-3.svg';
import imageNine from 'assets/img/service/service-1-illustration-1.svg';
import imageTen from 'assets/img/service/service-1-illustration-4.svg';
import Logo from '../../components/header/elements/logo';
import HeaderContainer from 'container/header/HeaderContainerOne';
import Button from 'components/button';
import CardOne from 'components/card/CardOne';
import imageFive from 'assets/img/service/service-2-illustration-5.svg';
import ServiceItemTwo from 'components/service/ServiceItemTwo';
import companyData from 'data/testimonial/company.json';
import gsap from 'gsap';
import { ScrollTrigger, MotionPathPlugin } from 'gsap/all';
import Awards from 'container/awards/homes/Awards';
import './Landing.scss';

const HomeAirbag = () => {
  const formRef = useRef(null);

  gsap.registerPlugin(ScrollTrigger);
  gsap.registerPlugin(MotionPathPlugin, ScrollTrigger);

  useEffect(() => {
    const images = document.querySelectorAll('img');
    let isLoaded = false;
    let isLoadingAnimationEnd = false;
    const imgLoad = imagesLoaded(images);

    const entranceAnimation = () => {
      const tl = gsap.timeline();
      tl.to(
        '.hero-banner-wrapper',
        {
          duration: 1,
          opacity: 1,
          y: 0,
          stagger: 0.1,
          ease: 'power2.out',
          onComplete: () => {
            ScrollTrigger.refresh();
            if (document.querySelector('.loading-wrapper')) {
              document
                .querySelector('#wrapper')
                .removeChild(document.querySelector('.loading-wrapper'));
            }

            if (window.innerWidth > 992) {
              gsap.to('.globe-l', {
                stroke: 'white',
                ease: 'none',
                scrollTrigger: {
                  trigger: '.brook-our-awards',
                  start: 'top 10%',
                  end: '1%',
                  scrub: true
                  // markers: true,
                }
              });

              gsap.to('.globe-indicator', {
                color: 'white',
                ease: 'none',
                scrollTrigger: {
                  trigger: '.brook-our-awards',
                  start: 'top 10%',
                  end: '1%',
                  scrub: true
                  // markers: true,
                }
              });
            }

            gsap.to('.dot-logo', {
              fill: 'white',
              ease: 'none',
              duration: 0.1,
              scrollTrigger: {
                trigger: '.change-logo-dot',
                start: 'top 10%',
                end: 'bottom top',
                scrub: true
                // markers: true,
              }
            });
          }
        },
        0.6
      );
    };

    const loadingAnimation = () => {
      gsap.timeline({
        onComplete: () => {
          isLoadingAnimationEnd = true;
          if (isLoaded) entranceAnimation();
        }
      });
    };

    loadingAnimation();

    imgLoad.on('always', function () {
      isLoaded = true;
      if (isLoadingAnimationEnd) entranceAnimation();
    });
  }, []);

  function handleScrollToForm() {
    document.querySelector('#demo-request-iframe').scrollIntoView({
      behavior: 'smooth'
    });
  }

  const sliderSettings = {
    speed: 5000,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    swipeToSlide: true,
    centerMode: true,
    focusOnSelect: true,
    arrows: false,
    dots: false,
    responsive: [
      {
        breakpoint: 801,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 577,
        settings: {
          slidesToShow: 3
        }
      }
    ]
  };

  return (
    <Fragment>
      <Helmet>
        <title>{`Home | Landing App`}</title>
      </Helmet>
      <LayoutDefault className="template-color-4 template-font-1">
        {/* Header */}
        <HeaderContainer
          logoVersion={'dark'}
          transparent={true}
          sticky={true}
          classes="position-from--top"
        >
          <div className="header-left">
            <Logo />
          </div>

          <div className="header-right flex-20">
            <div className="header-right have-not-flex">
              <div className="header-buttons dark-logo--version d-none d-lg-flex">
                <Button
                  type={''}
                  btnText="Solicitar demo"
                  btnLink={''}
                  arrow={true}
                  size={'sd'}
                  color={'theme'}
                  btnStyle={'bullet'}
                  className={'header-right-button'}
                  onClick={() => {
                    handleScrollToForm();
                  }}
                />
              </div>
            </div>
          </div>
        </HeaderContainer>
        <div className="panel">
          <div
            className="hero-banner-wrapper hero-service"
            style={{ height: '100vh', paddingTop: '20px' }}
          >
            <Container>
              <Row style={{ paddingTop: '0px' }}>
                <Col lg={6}>
                  <div className="service airbag">
                    <h2 style={{ marginTop: '20px' }}>
                      Evalúa los hábitos de conducción y fomenta el manejo
                      seguro
                    </h2>

                    <p
                      className="text-description"
                      style={{ marginBottom: '20px' }}
                    >
                      Analiza, retro-alimenta y premia a tus conductores
                    </p>

                    <Button
                      type={''}
                      btnText="Solicitar demo"
                      btnLink={''}
                      arrow={false}
                      size={'sd'}
                      color={'theme'}
                      btnStyle={'bullet'}
                      onClick={() => {
                        handleScrollToForm();
                      }}
                    />
                  </div>
                </Col>

                <Col lg={6}>
                  {/* <img src={image} alt="architect" /> */}
                  <div className="hero-service-content">
                    <video
                      className={`hero-service-web service-two`}
                      id="hero-main-video"
                      playsInline
                      autoPlay="autoplay"
                      loop
                      muted
                      poster={heroImageTwo}
                    >
                      <source src={heroVideoTwo} type="video/mp4" />
                    </video>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className="bg_color--1" style={{ padding: ' 0 20px' }}>
          <div className="fun-fact-container">
            <Row>
              <Col xs={12} md={6} lg={3} className="d-flex align-items-stretch">
                <CardOne
                  title="Disminuye la rotación"
                  cardImage={imageOne}
                  imageClass={
                    'align-self-end mt--30 mb--40 pl_sm--20 pr_sm--20'
                  }
                />
              </Col>
              <Col xs={12} md={6} lg={3} className="d-flex align-items-stretch">
                <CardOne
                  title="Ahorra combustible"
                  cardImage={imageTwo}
                  imageClass={
                    'align-self-center mt--30 mb--40 pl_sm--20 pr_sm--20'
                  }
                />
              </Col>
              <Col
                xs={12}
                md={6}
                lg={3}
                className="d-flex align-items-stretch crash-three"
              >
                <CardOne
                  title="Ahorra costos de accidentes"
                  cardImage={imageThree}
                  imageClass={
                    'align-self-center mt--30 mb--40 pl_sm--20 pr_sm--20'
                  }
                />
              </Col>
              <Col
                xs={12}
                md={6}
                lg={3}
                className="d-flex align-items-stretch crash-four"
              >
                <CardOne
                  title="Reduce el desgaste de tus unidades"
                  cardImage={imageFour}
                  imageClass={
                    'align-self-center mt--30 mb--40 pl_sm--20 pr_sm--20'
                  }
                />
              </Col>
            </Row>
          </div>
        </div>
        <div className="panel change-logo-dot" id="video-div">
          <div
            className="bk-about-area bg_color--24 before-panel scroll-animation-one dot-logo-first-section"
            style={{ padding: '120px 0px', marginTop: '120px' }}
          >
            <Container fluid="sm">
              <Row>
                <Col xs={12}>
                  <div className="brook-section-title service service-center text-center">
                    <h3>
                      La mala conducción es el principal costo de tu flota
                    </h3>
                    <p className="text-description">
                      Analizamos el riesgo al volante identificando los excesos
                      de velocidad, tiempo de uso del celular, aceleraciones,
                      frenados y giros bruscos
                    </p>
                    <div style={{ margin: '25px 0 25px 0' }}>
                      <iframe
                        src="https://www.youtube-nocookie.com/embed/7s5HIHc29J4"
                        width="100%"
                        className="video-about"
                      />
                    </div>
                    <Button
                      type={''}
                      btnText="Solicitar demo"
                      btnLink={''}
                      arrow={true}
                      size={'sd'}
                      color={'theme'}
                      btnStyle={'bullet'}
                      onClick={() => {
                        handleScrollToForm();
                      }}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div id="demo-request-iframe" ref={formRef}>
          <iframe
            src="https://forms.monday.com/forms/embed/a9d628b7de9b7d8fcc8c4ff05096589a?r=use1"
            height="1200"
            width="100%"
          ></iframe>
        </div>
        <div
          className="hero-banner-wrapper full-sentence-div"
          style={{ height: '400px', padding: '0 20px' }}
        >
          <Container fluid="l">
            <Row>
              <Col lg={12}>
                <div
                  className="service airbag"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <h2 style={{ textAlign: 'center', marginBottom: '25px' }}>
                    Solicita un demo hoy y obtén <br />
                    <b>3 meses gratis de Airbag</b>
                  </h2>

                  <Button
                    type={''}
                    btnText={'Lo quiero'}
                    btnLink={''}
                    arrow={false}
                    size={'sd'}
                    color={'theme-secondary'}
                    btnStyle={'bullet'}
                    onClick={() => {
                      handleScrollToForm();
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="panel remove-panel-mobile" id="hero-animation">
          <div
            className="hero-banner-wrapper bg_color--26"
            style={{ height: '100%', padding: '30px 0' }}
          >
            <Container fluid="sm">
              <Row>
                <Col xs={12} className="text-center">
                  <div className="hero-banner-content">
                    <video
                      className="hero-home-web"
                      playsInline
                      autoPlay="autoplay"
                      loop
                      muted
                      poster={heroImageHero3}
                    >
                      <source src={heroVideoHero3} type="video/mp4" />
                    </video>

                    <h2 className="hero-home-title">
                      Mejoramos y retenemos al talento operador
                    </h2>

                    <p>
                      Obtén análisis y reportes de desempeño al mismo tiempo que
                      incentivas el buen manejo por medio de puntos canjeables
                      por premios
                    </p>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
        <div className="bg_color--1" style={{ padding: ' 0 20px' }}>
          <div className="fun-fact-container">
            <Row>
              <Col
                xs={12}
                md={6}
                lg={4}
                className="d-flex align-items-stretch illust-eight"
              >
                <CardOne
                  cardImage={imageEight}
                  imageClass={
                    'align-self-end mt--30 mb--40 pl_sm--20 pr_sm--20'
                  }
                />
              </Col>
              <Col
                xs={12}
                md={6}
                lg={4}
                className="d-flex align-items-stretch illust-nine"
              >
                <CardOne
                  cardImage={imageNine}
                  imageClass={
                    'align-self-center mt--30 mb--40 pl_sm--20 pr_sm--20'
                  }
                />
              </Col>
              <Col
                xs={12}
                md={6}
                lg={4}
                className="d-flex align-items-stretch illust-ten"
              >
                <CardOne
                  cardImage={imageTen}
                  imageClass={
                    'align-self-center mt--30 mb--40 pl_sm--20 pr_sm--20'
                  }
                />
              </Col>
            </Row>
          </div>
        </div>
        <div
          className="hero-banner-wrapper full-sentence-div"
          style={{ height: '400px', padding: '0 20px' }}
        >
          <Container fluid="l">
            <Row>
              <Col lg={12}>
                <div
                  className="service airbag"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  <h2 style={{ textAlign: 'center', marginBottom: '25px' }}>
                    ¿Quieres probar Airbag <br />
                    <b>3 meses completamente gratis</b>?
                  </h2>

                  <Button
                    type={''}
                    btnText={'Obtener promoción'}
                    btnLink={''}
                    arrow={false}
                    size={'sd'}
                    color={'theme-secondary'}
                    btnStyle={'bullet'}
                    onClick={() => {
                      handleScrollToForm();
                    }}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="" style={{ padding: '20px 0', marginBottom: '50px' }}>
          <Container fluid="sm">
            <Row>
              <Col xs={12} lg={12} className="d-flex align-items-stretch">
                <CardOne
                  content="
                  Te llevaremos de la mano cada paso del camino, dando capacitaciones a todos los operadores, administradores y ejecutivos asegurando el conocimiento sobre la herramienta para obtener los mejores resultados"
                  title="Soporte a administradores"
                  cardImage={imageFive}
                  imageClass={
                    'col-lg-4 align-lg-self-end pr_md--100 pl_md--100'
                  }
                  contentClass={'col-lg-8'}
                  classes={'flex-lg-row'}
                />
              </Col>
            </Row>
          </Container>
        </div>
        <Container fluid="sm">
          <Row>
            <Col xs={12} className="text-center">
              <div className="brook-section-title text-center testimonial-airbag">
                <h3>Un impacto real para tu empresa</h3>
                <p className="text-description">+ 37 compañías impactadas</p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div className="company-container" style={{ marginTop: '42px' }}>
                <SlickSlider
                  settings={sliderSettings}
                  classes="cars-road brook-element-carousel slick-arrow-center arrow-transparent slick-gutter-15"
                >
                  {companyData.map((company) => (
                    <ServiceItemTwo key={company.id} data={company} />
                  ))}
                </SlickSlider>
              </div>
            </Col>
          </Row>
          <Row>
            <Col style={{ display: 'flex', justifyContent: 'center' }}>
              <div style={{ width: '300px', marginTop: '50px' }}>
                <Button
                  type={''}
                  btnText="Solicitar demo"
                  btnLink={''}
                  arrow={true}
                  size={'sd'}
                  color={'theme'}
                  btnStyle={'bullet'}
                  onClick={() => {
                    handleScrollToForm();
                  }}
                />
              </div>
            </Col>
          </Row>
        </Container>
        <div className="testimonial-content" style={{ paddingTop: '0px' }}>
          <div className="collage">
            <img src={collage} alt="testimonial-collage" key="1" />
          </div>
        </div>
        <Awards bgColor={'black'} />
      </LayoutDefault>
      <footer
        className={`page-footer pl--150 pr--150 pl_lg--30 pr_lg--30 pl_md--30 pr_md--30 pl_sm--5 pr_sm--5 before-panel bg_color--3`}
      >
        <div className="bk-footer-inner pt--30 pb--30 pt_sm--100">
          <Container fluid="sm" className="airbag-footer">
            <Row>
              <Col className="mt_mobile--40">
                <Widget wrapClasses="text-var--2 menu--about" title="Legal">
                  <div className="footer-menu">
                    <ul className="ft-menu-list bk-hover">
                      <li>
                        <Link to={process.env.PUBLIC_URL + '/terms'}>
                          Términos y condiciones
                        </Link>
                      </li>
                      <li>
                        <Link to={process.env.PUBLIC_URL + '/privacy'}>
                          Aviso de privacidad
                        </Link>
                      </li>
                    </ul>
                  </div>
                </Widget>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
    </Fragment>
  );
};

export default HomeAirbag;
