import { Helmet } from 'react-helmet';
import brook from 'data/brook.json';
import React, { Fragment } from 'react';

import Footer from 'container/footer/FooterOne';
import LayoutDefault from 'layouts/LayoutDefault';
import Header from 'components/header/Header';
import Awards from 'container/awards/homes/Awards';
import TestimonialCard from 'components/testimonial/TestimonialCard';
import peoples from 'data/testimonial/peoples.json';
import collage from 'assets/img/testimonial/testimonios-collage.png';

import { useTranslation } from 'react-i18next';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';

const TestimonialAirbag = () => {
  const { t } = useTranslation();
  gsap.registerPlugin(ScrollTrigger);

  return (
    <Fragment>
      <Helmet>
        <title>{`Testimonios | ${brook.title}`}</title>
      </Helmet>
      <LayoutDefault className="template-color-4 template-font-1">
        <Header />

        <div className="testimonial-content">
          <div className="page-title">
            <h1 dangerouslySetInnerHTML={{ __html: t('testimonial_title') }} />
            <p
              className="text-description"
              dangerouslySetInnerHTML={{
                __html: t('testimonial_description')
              }}
            />
          </div>
          <div className="collage">
            <img src={collage} alt="testimonial-collage" key="1" />
          </div>
          <div className="cards-container">
            {peoples.map((people) => (
              <TestimonialCard
                description={people.description}
                name={people.name}
                job={people.job}
                image={people.image}
                videoUrl={people.videoUrl}
                key={people.id}
              />
            ))}
          </div>
        </div>
        <Awards bgColor={'black'} />
      </LayoutDefault>
      <Footer className="bg_color--3" />
    </Fragment>
  );
};

export default TestimonialAirbag;
