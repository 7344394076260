import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const ContactSection = () => {
  const { t, i18n } = useTranslation();

  return (
    <div className="section-pt-xl">
      <Container fluid="sm">
        <Row className="justify-content-center">
          <Col xs={12} lg={10}>
            <div className="text-center" style={{ paddingBottom: '30px' }}>
              <h1 dangerouslySetInnerHTML={{ __html: t('contact_title') }} />
              <p
                className="text-description"
                dangerouslySetInnerHTML={{ __html: t('contact_description') }}
              />
            </div>
          </Col>
        </Row>
      </Container>
      {i18n && i18n.language === 'es' ? (
        <iframe
          title="contact-form-monday"
          src="https://forms.monday.com/forms/embed/c8dc2ba14f15aac0f8f0aabe4cc5bba9?r=use1"
          width="100%"
          height="1200"
          style={{ border: 'none' }}
        ></iframe>
      ) : (
        <iframe
          title="contact-form-monday"
          src="https://forms.monday.com/forms/embed/9aef7fcb0db821d66d6cbc9d2f109dc3?r=use1"
          width="100%"
          height="1200"
          style={{ border: 'none' }}
        ></iframe>
      )}
    </div>
  );
};

export default ContactSection;
