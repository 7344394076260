import { Link } from 'react-router-dom';
import React, { useState, useEffect } from 'react';

import { ReactComponent as Logo } from 'assets/img/logo/airbag-logo-dark-dot-w.svg';

import Button from 'components/button';

import i18next from 'i18next';
import Cookies from 'js-cookie';
import { ReactComponent as Globe } from 'assets/img/icons/globe.svg';

import { useTranslation } from 'react-i18next';

const PopupMobileMenu = () => {
  const { t } = useTranslation();

  const [currentLanguage, setCurrentLanguage] = useState(
    Cookies.get('i18next') || 'es'
  );

  const [menuData] = useState(require('data/navbar'));

  const closeMobileMenu = () => {
    const body = document.querySelector('body');
    const html = document.querySelector('html');
    body.classList.remove('popup-mobile-menu-wrapper');
    html.style.overflow = 'auto';
  };

  useEffect(() => {
    const responsiveMenu = () => {
      const menulist = document.querySelector('.menulist');
      const navWrapper = document.querySelector('.object-custom-menu');
      const hasMegaMenu = document.querySelectorAll('.has-mega-menu');
      const navSubMenus = navWrapper.querySelectorAll('.object-submenu');

      navSubMenus.forEach((navSubMenu) => {
        navSubMenu.style.display = 'none';
      });

      hasMegaMenu.forEach((hasSubMenuAnchor) => {
        hasSubMenuAnchor.firstChild.addEventListener('click', (e) => {
          e.preventDefault();
          if (e.target.classList.contains('active')) {
            e.target.classList.remove('active');
          } else {
            e.target.classList.add('active');
          }
          let subMenus = e.target.parentNode.querySelectorAll('ul');
          subMenus.forEach((subMenu) => {
            if (subMenu.style.display === 'none') {
              subMenu.style.display = 'block';
            } else {
              subMenu.style.display = 'none';
            }
            let anchors = subMenu.querySelectorAll('a');
            anchors.forEach((anchor) => {
              anchor.addEventListener('click', () => closeMobileMenu());
            });
          });
        });
      });

      let anchors = menulist.querySelectorAll('a');
      anchors.forEach((anchor) => {
        if (anchor.className !== 'sub-menu') {
          anchor.addEventListener('click', () => closeMobileMenu());
        }
      });

      const header = document.querySelector('.mobileheader');
      let logo = header.firstChild;
      logo.addEventListener('click', () => closeMobileMenu());

      const buttonsC = document.querySelector('.popup-mobile-menu');
      let buttons = buttonsC.querySelectorAll('.popup-mobile-button');
      buttons.forEach((button) => {
        button.addEventListener('click', () => closeMobileMenu());
      });
    };
    responsiveMenu();
  }, []);

  return (
    <div className="popup-mobile-menu popup-mobile-visible">
      <div
        className="overlay"
        onClick={() => {
          closeMobileMenu();
        }}
      />
      <div className="inner">
        <div className="mobileheader">
          <div className="logo">
            <Link to={process.env.PUBLIC_URL + '/'}>
              <Logo />
            </Link>
          </div>

          {currentLanguage === 'es' ? (
            <button
              className="lenguage-b globe-indicator"
              style={{
                border: `none`,
                padding: `0`,
                display: `flex`,
                alignItems: `center`
              }}
              onClick={() => {
                i18next.changeLanguage('en');
                setCurrentLanguage('en');
              }}
              disabled={'en' === currentLanguage}
            >
              <div
                style={{
                  display: `flex`,
                  alignItems: `center`,
                  marginRight: `6px`
                }}
              >
                <Globe />
              </div>
              <span>EN</span>
            </button>
          ) : (
            <button
              className="lenguage-b"
              style={{
                border: `none`,
                padding: `0`,
                display: `flex`,
                justifyContent: `center`,
                alignItems: `center`
              }}
              onClick={() => {
                i18next.changeLanguage('es');
                setCurrentLanguage('es');
              }}
              disabled={'es' === currentLanguage}
            >
              <div
                style={{
                  display: `flex`,
                  alignItems: `center`,
                  marginRight: `6px`
                }}
              >
                <Globe />
              </div>
              <span>ES</span>
            </button>
          )}

          <button
            onClick={() => {
              closeMobileMenu();
            }}
            className="mobile-close"
          />
        </div>
        <div className="menu-content">
          <ul className="menulist object-custom-menu">
            {menuData.map((menuItem) => (
              <li
                key={menuItem.id}
                className={
                  menuItem.megaMenu || menuItem.subMenu ? 'has-mega-menu' : ''
                }
              >
                {menuItem.megaMenu || menuItem.subMenu ? (
                  <Link
                    to={`${process.env.PUBLIC_URL + menuItem.link}`}
                    className="sub-menu"
                  >
                    <span>{t(menuItem.title)}</span>
                  </Link>
                ) : (
                  <Link to={`${process.env.PUBLIC_URL + menuItem.link}`}>
                    <span>{t(menuItem.title)}</span>
                  </Link>
                )}

                {menuItem.subMenu ? (
                  <ul className="object-submenu mt-0">
                    {menuItem.subMenu.lists.map((subItem) => (
                      <li key={subItem.id}>
                        <Link to={`${process.env.PUBLIC_URL + subItem.link}`}>
                          <span>{t(subItem.title)}</span>
                        </Link>
                      </li>
                    ))}
                  </ul>
                ) : null}

                {menuItem.megaMenu
                  ? menuItem.megaMenu.map((megaItem) => (
                      <ul key={megaItem.id} className="object-submenu">
                        <li className="title">{megaItem.megaTitle}</li>
                        {megaItem.lists.map((list) => (
                          <li key={list.id}>
                            <Link to={`${process.env.PUBLIC_URL + list.link}`}>
                              <span>{list.title}</span>
                            </Link>
                          </li>
                        ))}
                      </ul>
                    ))
                  : null}
              </li>
            ))}
          </ul>
        </div>

        <div className="header-buttons dark-logo--version flex-column">
          <div className="log-in-link">
            <a href="https://app.airbagtech.io" className="popup-mobile-button">
              <span>{t('button_one')}</span>
              <div className="over">
                <span>{t('button_one')}</span>
              </div>
            </a>
          </div>

          <Button
            type={'link'}
            btnText={t('button_two')}
            btnLink={'/solicitud-de-demo'}
            arrow={true}
            size={'sd'}
            color={'theme'}
            btnStyle={'bullet'}
            className={'header-right-button popup-mobile-button'}
          />
        </div>
      </div>
    </div>
  );
};

export default PopupMobileMenu;
