import { Helmet } from 'react-helmet';
import brook from 'data/brook.json';
import React, { Fragment, useLayoutEffect, useRef, useState } from 'react';

import Footer from 'container/footer/FooterOne';
import LayoutDefault from 'layouts/LayoutDefault';
import Header from 'components/header/Header';
import AboutOne from 'container/about-us/AboutOne';
import AboutTwo from 'container/about-us/AboutTwo';
import AboutThree from 'container/about-us/AboutThree';
import HeroBanner from 'container/hero-banner/HeroHome';
import Awards from 'container/awards/homes/Awards';
import Counter from 'container/counter/Counter';
import Testimonial from 'container/testimonial/elements/TestimonialAirbag';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';

const HomeAirbag = () => {
  gsap.registerPlugin(ScrollTrigger);
  const [playVideo, setPlayVideo] = useState(false);
  const iframeAllowstring =
    'accelerometer; autoplay; clipboard-write; ' +
    'encrypted-media; gyroscope; picture-in-picture';
  const [videoUrl, setVideoUrl] = useState(false);

  const scrollAnimationRef = useRef();

  useLayoutEffect(() => {
    const q = gsap.utils.selector(scrollAnimationRef);

    // remove panel interaction on mobile

    if (window.innerWidth <= 992) {
      const listP = document.querySelectorAll('.remove-panel-mobile');
      listP.forEach((element) => {
        element.classList.remove('panel');
      });
    }

    const tl = gsap.timeline();
    tl.to(
      '.hero-banner-wrapper',
      {
        duration: 1,
        opacity: 1,
        y: 0,
        stagger: 0.1,
        ease: 'power2.out'
      },
      0.6
    );

    // Panel animation
    gsap.utils.toArray(q('.panel')).forEach((panel) => {
      ScrollTrigger.create({
        trigger: panel,
        start: 'bottom bottom',
        end: 'bottom top',
        pin: true,
        pinSpacing: false,
        scrub: true
        // markers: true,
      });
    });

    return () => {
      // console.log(ScrollTrigger.getAll());
      ScrollTrigger.getAll().forEach((st) => st.kill());
      // console.log(ScrollTrigger.getAll());
    };
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>{`Home | ${brook.title}`}</title>
      </Helmet>
      <LayoutDefault
        className="template-color-4 template-font-1"
        refContainer={scrollAnimationRef}
      >
        <Header />
        <div className="panel remove-panel-mobile">
          <HeroBanner />
        </div>
        <div className="panel change-logo-dot remove-panel-mobile">
          <AboutOne />
          <AboutTwo />
          <AboutThree />
        </div>
        <div className="panel change-logo-dot-normal min-vh-100 bg_color--1 d-flex flex-column justify-content-center">
          <Counter />
          <Testimonial setVideoUrl={setVideoUrl} setPlayVideo={setPlayVideo} />
        </div>
        {playVideo && (
          <div id="_manual-video_">
            <div
              id="manuals-courtain"
              onClick={() => {
                setPlayVideo(false);
                document.body.classList.remove('no-scroll');
              }}
            >
              <div id="courtain-content">
                <iframe
                  src={videoUrl}
                  allow={iframeAllowstring}
                  allowFullScreen
                  style={{ border: 'none' }}
                />
              </div>
            </div>
          </div>
        )}
        <Awards bgColor={'black'} />
      </LayoutDefault>
      <Footer className="bg_color--3" />
    </Fragment>
  );
};

export default HomeAirbag;
