import React from 'react';
import { Row, Col, Container } from 'react-bootstrap';
import Button from 'components/button';

const JoinTheTeam = ({ title, content, btnText, image, imageClassName }) => {
  return (
    <div className="brook-story-area section-pt-xl bg_color--23 join-the-team-section before-panel">
      <Container fluid="sm">
        <Row>
          <Col xs={12} lg={12}>
            <div className="">
              <div className="service airbag">
                <h1>{title}</h1>

                <p className="text-description">{content}</p>

                <Button
                  type={'link'}
                  btnText={btnText}
                  btnLink={
                    'https://airbagtech.notion.site/Public-Bolsa-de-Trabajo-157be92c82a64ceeb96dd0b9f80589ac'
                  }
                  linkType="external"
                  arrow={false}
                  size={'sd'}
                  color={'theme'}
                  btnStyle={'bullet'}
                />
              </div>
            </div>
          </Col>

          <Col
            xs={12}
            lg={12}
            className={`${imageClassName && imageClassName}`}
          >
            <div className={`mt_md--40 mt_sm--40`}>
              <div className="architecture-story">
                <div className="thumb">
                  <img src={image} alt="architect" />
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default JoinTheTeam;
