import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import TeamMemberItem from 'components/team-member/TeamMemberItemThree';
import teamMembers from 'data/team-member';

import { useTranslation } from 'react-i18next';

const TeamMember = () => {
  const { t } = useTranslation();
  return (
    <div className="brook-team-area bg_color--1 section-pb-xl pt--170 pt_md--120 pt_md--100 height-team-section">
      <Container fluid="sm">
        <Row>
          <Col lg={12}>
            <div className="team-content text-center mb--60">
              <h1
                dangerouslySetInnerHTML={{ __html: t('team_memebers_title') }}
              />

              <p
                className="text-description"
                dangerouslySetInnerHTML={{
                  __html: t('team_memebers_description')
                }}
              />
            </div>
          </Col>
        </Row>

        <Row className="mt--n40">
          {teamMembers.map((member) => (
            <Col
              key={member.id}
              sm={6}
              md={4}
              lg={3}
              className="mt--40 mt_sm--30"
            >
              <TeamMemberItem data={member} />
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};

export default TeamMember;
