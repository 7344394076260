import React, { Fragment, useEffect } from 'react';
import brook from 'data/brook.json';
import { Helmet } from 'react-helmet';
import LayoutDefault from 'layouts/LayoutDefault';
import AppLinkContainer from 'container/app-link/AppLinkContainer';

const AppLink = () => {
  useEffect(() => {
    // Redirect to play store link
    window.location.replace('market://details?id=com.airbag.app');
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>{`Airbag App | ${brook.title}`}</title>
      </Helmet>

      <LayoutDefault className="template-color-1 template-font-1">
        <AppLinkContainer />
      </LayoutDefault>
    </Fragment>
  );
};

export default AppLink;
