import React, { Fragment, useLayoutEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import brook from 'data/brook.json';

import LayoutDefault from 'layouts/LayoutDefault';
import Header from 'components/header/Header';
import TerminosCondicionesC from 'container/about-us/TerminosCondicionesC';
import Awards from 'container/awards/homes/Awards';
import Footer from 'container/footer/FooterOne';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';

const TerminosCondiciones = () => {
  gsap.registerPlugin(ScrollTrigger);

  const scrollAnimationRef = useRef();

  useLayoutEffect(() => {
    const q = gsap.utils.selector(scrollAnimationRef);

    // Logo color

    gsap.to(q('.letter-logo'), {
      fill: 'white',
      ease: 'none',
      scrollTrigger: {
        trigger: q('.brook-our-awards'),
        start: 'top 10%',
        end: '1%',
        scrub: true
        // markers: true,
      }
    });

    //language

    if (window.innerWidth > 992) {
      gsap.to('.globe-l', {
        stroke: 'white',
        ease: 'none',
        scrollTrigger: {
          trigger: '.brook-our-awards',
          start: 'top 10%',
          end: '1%',
          scrub: true
          // markers: true,
        }
      });

      gsap.to('.globe-indicator', {
        color: 'white',
        ease: 'none',
        scrollTrigger: {
          trigger: '.brook-our-awards',
          start: 'top 10%',
          end: '1%',
          scrub: true
          // markers: true,
        }
      });
    }

    return () => {
      // console.log(ScrollTrigger.getAll());
      ScrollTrigger.getAll().forEach((st) => st.kill());
      // console.log(ScrollTrigger.getAll());
    };
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>{`Términos y condiciones | ${brook.title}`}</title>
      </Helmet>

      <LayoutDefault
        className="template-color-4 template-font-1"
        refContainer={scrollAnimationRef}
      >
        <Header transparent={true} />

        <TerminosCondicionesC />

        <Awards bgColor={'black'} />
      </LayoutDefault>
      <Footer className=" bg_color--3" />
    </Fragment>
  );
};

export default TerminosCondiciones;
