import React from 'react';

const CardOne = ({
  title,
  content,
  cardImage,
  classes,
  imageClass,
  contentClass
}) => {
  return (
    <div className={`card_style--1 ${classes && classes}`}>
      <div className={`content ${contentClass && contentClass}`}>
        <div className="testimonial-info">
          <div className="client-info">
            <h3>{title}</h3>
          </div>
        </div>

        <p dangerouslySetInnerHTML={{ __html: content }} />
      </div>
      {cardImage !== undefined ? (
        <img
          className={imageClass}
          src={cardImage}
          alt="service illustration"
        />
      ) : null}
    </div>
  );
};

export default CardOne;
