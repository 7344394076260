import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import SlickSlider from 'components/slick';
import testimonialData from 'data/testimonial';
import companyData from 'data/testimonial/company.json';
import TestimonialItemOne from 'components/testimonial/TestimonialItemOne';
import ServiceItemTwo from 'components/service/ServiceItemTwo';
import Button from 'components/button';

import { useTranslation } from 'react-i18next';

const TestimonialAirbag = ({ setPlayVideo, setVideoUrl }) => {
  const { t } = useTranslation();
  const NextArrow = ({ className, onClick }) => {
    return (
      <button className={`slick-btn ${className}`} onClick={onClick}>
        <i className="fa fa-angle-right" />
      </button>
    );
  };

  const PrevArrow = ({ className, onClick }) => {
    return (
      <button className={`slick-btn ${className}`} onClick={onClick}>
        <i className="fa fa-angle-left" />
      </button>
    );
  };

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    initialSlide: 2,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1
        }
      },
      {
        breakpoint: 770,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };

  const sliderSettings = {
    speed: 5000,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: 'linear',
    slidesToShow: 4,
    slidesToScroll: 1,
    infinite: true,
    swipeToSlide: true,
    centerMode: true,
    focusOnSelect: true,
    arrows: false,
    dots: false,
    responsive: [
      {
        breakpoint: 801,
        settings: {
          slidesToShow: 4
        }
      },
      {
        breakpoint: 577,
        settings: {
          slidesToShow: 3
        }
      }
    ]
  };

  return (
    <div className="brook-testimonial-area section-ptb-xl bg_color--1 slick-arrow-hover">
      <Container fluid="sm">
        <Row>
          <Col xs={12} className="text-center">
            <div className="brook-section-title text-center testimonial-airbag">
              <h3
                dangerouslySetInnerHTML={{
                  __html: t('general_section_testimonial_title')
                }}
              />

              <p
                className="text-description"
                dangerouslySetInnerHTML={{
                  __html: t('general_section_testimonial_description')
                }}
              />
            </div>
          </Col>
        </Row>
        <Row style={{ marginBottom: 100 + 'px' }}>
          <Col>
            <div className="company-container" style={{ marginTop: '42px' }}>
              <SlickSlider
                settings={sliderSettings}
                classes="cars-road brook-element-carousel slick-arrow-center arrow-transparent slick-gutter-15"
              >
                {companyData.map((company) => (
                  <ServiceItemTwo key={company.id} data={company} />
                ))}
              </SlickSlider>
            </div>
          </Col>
          <Col>
            <div className="brook-section-title text-center testimonial-airbag">
              <Button
                type={'link'}
                btnText={t('button_three')}
                btnLink={'/app'}
                arrow={false}
                size={'sd'}
                color={'theme'}
                btnStyle={'bullet'}
              />
            </div>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>
          <Col xs={12} className={'pb--30 mt--40 '}>
            <h3
              dangerouslySetInnerHTML={{
                __html: t('general_section_testimonial_title_two')
              }}
              style={{ paddingTop: 15 + 'px', paddingBottom: 15 + 'px' }}
              className="text-center"
            />
            <h4
              dangerouslySetInnerHTML={{
                __html: t('general_section_testimonial_title_three')
              }}
              style={{ paddingTop: 15 + 'px', paddingBottom: 15 + 'px' }}
              className="text-center"
            />
            <SlickSlider
              settings={settings}
              classes={
                'brook-element-carousel slick-arrow-center slick-dots-bottom slick-gutter-15'
              }
            >
              {testimonialData.map((testimonial) => (
                <TestimonialItemOne
                  key={testimonial.id}
                  data={testimonial}
                  setPlayVideo={setPlayVideo}
                  setVideoUrl={setVideoUrl}
                />
              ))}
            </SlickSlider>
            <div className="brook-section-title text-center testimonial-airbag">
              <Button
                type={'link'}
                btnText={t('button_seven')}
                btnLink={'/testimonios'}
                arrow={false}
                size={'sd'}
                color={'theme'}
                btnStyle={'bullet'}
              />
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default TestimonialAirbag;
