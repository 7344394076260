import React, { useLayoutEffect, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Button from 'components/button';
// import data from "data/animation/home-one.json"
import { ReactComponent as CarRoad1 } from 'assets/img/animation/car-road-1.svg';
import { ReactComponent as CarRoad2 } from 'assets/img/animation/car-road-2.svg';
import { ReactComponent as CarRoad3 } from 'assets/img/animation/car-road-3.svg';
import { ReactComponent as CarRoad4 } from 'assets/img/animation/car-road-4.svg';
import { ReactComponent as CarRoad5 } from 'assets/img/animation/car-road-5.svg';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';

import { useTranslation } from 'react-i18next';

const AboutOne = () => {
  const { t } = useTranslation();
  gsap.registerPlugin(ScrollTrigger);

  const scrollAnimationRef = useRef();

  useLayoutEffect(() => {
    const q = gsap.utils.selector(scrollAnimationRef);

    // First yellow section animation

    const animation1 = gsap.set(q('.road-car'), {
      x: (i) => i * 400
    });

    const animation2 = gsap.set(q('.bad-driver'), {
      y: 90
    });

    gsap.to(q('.road-car-container'), {
      xPercent: 50,
      ease: 'none',
      scrollTrigger: {
        trigger: scrollAnimationRef.current,
        start: 'top bottom',
        end: 'bottom top',
        scrub: true
      }
    });

    gsap.from(q('.autoAlphaAnim'), {
      autoAlpha: 0,
      duration: 10,
      scrollTrigger: {
        trigger: scrollAnimationRef.current,
        start: 'top center',
        end: 'bottom bottom',
        scrub: true
      }
    });

    return () => {
      animation1.kill();
      animation2.kill();
    };
  }, []);

  return (
    <div
      className="bk-about-area section-ptb-xl bg_color--24 round-radius-top before-panel scroll-animation-one dot-logo-first-section"
      ref={scrollAnimationRef}
    >
      <Container fluid="sm">
        <Row>
          <Col xs={12}>
            <div className="brook-section-title service service-center text-center">
              <h3
                dangerouslySetInnerHTML={{ __html: t('home_fact_one_title') }}
              />
              <p
                className="text-description"
                dangerouslySetInnerHTML={{
                  __html: t('home_fact_one_description')
                }}
              />
              <div style={{ marginTop: '22px' }}>
                <iframe
                  width="600"
                  height="315"
                  src="https://www.youtube-nocookie.com/embed/7s5HIHc29J4"
                />
              </div>
              <Button
                type={'link'}
                btnText={t('button_three')}
                btnLink={'/app'}
                arrow={false}
                size={'sd'}
                color={'theme-secondary'}
                btnStyle={'bullet'}
              />
            </div>
          </Col>
        </Row>

        <div className="wrapper-road">
          <div className="road-container">
            <div className="road">
              <img
                src={require('assets/img/animation/road.svg').default}
                alt={'road-illustration'}
              />
            </div>

            <div className="road">
              <img
                src={require('assets/img/animation/road.svg').default}
                alt={'road-illustration'}
              />
            </div>

            <div className="road">
              <img
                src={require('assets/img/animation/road.svg').default}
                alt={'road-illustration'}
              />
            </div>
          </div>
        </div>
        <div className="road-animation">
          <div className="wrapper-road-car mt--40">
            <div className="road-car-container">
              <div className={`road-car`}>
                <CarRoad3 />
              </div>
              <div className={`road-car`}>
                <CarRoad4 />
              </div>
              <div className={`road-car`}>
                <CarRoad5 />
              </div>
              <div className={`road-car`}>
                <CarRoad1 />
              </div>
              <div className={`road-car bad-driver`}>
                <CarRoad2 />
              </div>
              <div className={`road-car`}>
                <CarRoad3 />
              </div>
              <div className={`road-car`}>
                <CarRoad4 />
              </div>
              <div className={`road-car`}>
                <CarRoad5 />
              </div>
              <div className={`road-car`}>
                <CarRoad1 />
              </div>
              <div className={`road-car bad-driver`}>
                <CarRoad2 />
              </div>

              {/* {
                                data.map((car, index) => (
                                    <div className={`road-car ${car.class}`} key={index}>
                                        <img src={require('assets/img/animation/' + car.image).default} alt={car.title} />
                                    </div>
                                ))
                            } */}
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};

export default AboutOne;
