import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Button from 'components/button';

const HeroService = ({
  title,
  description,
  btnText,
  heroVideo,
  heroImage,
  imageClassName,
  videoClassName
}) => {
  return (
    <div className="hero-banner-wrapper hero-service">
      <Container fluid="sm">
        <Row className="align-items-center">
          <Col xs={12} lg={6}>
            <div className="hero-service-content">
              <h1 dangerouslySetInnerHTML={{ __html: title }} />

              <p>{description}</p>

              <Button
                type={'link'}
                btnText={btnText}
                btnLink={'/solicitud-de-demo'}
                arrow={true}
                size={'sd'}
                color={'theme'}
                btnStyle={'bullet'}
              />
            </div>
          </Col>
          <Col xs={12} lg={6} className={imageClassName}>
            <div className="hero-service-content">
              <video
                className={`hero-service-web ${videoClassName}`}
                playsInline
                autoPlay="autoplay"
                loop
                muted
                poster={heroImage}
              >
                <source src={heroVideo} type="video/mp4" />
              </video>
              {/* <img className={`poster-video ${posterClassName}`} src={heroImage} alt="poster" /> */}

              {/* <img src={heroImage} alt="mouse icon" /> */}
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HeroService;
