export const isSticky = () => {
  const header = document.querySelector('.is-sticky');

  // Animation static to sticky
  // const scrollTop = window.scrollY;
  // header && scrollTop >= 100 ? header.classList.add('sticky') : header.classList.remove('sticky');

  // No animation static to sticky
  header.classList.add('sticky');
};
