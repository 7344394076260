import React, { useState } from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import CountUp from 'react-countup';

const CounterItemOne = ({ count }) => {
  const [appear, setAppear] = useState(false);

  const visibleHandler = (isVisible) => {
    isVisible && setAppear(true);
  };

  return (
    <div className="brook-counter text-center">
      <div className="content">
        <VisibilitySensor onChange={(e) => visibleHandler(e)}>
          <span className="count">
            <CountUp
              start={appear ? 0 : null}
              end={count}
              prefix="+ "
              suffix=" km"
              separator=","
            />
          </span>
        </VisibilitySensor>
      </div>
    </div>
  );
};

export default CounterItemOne;
