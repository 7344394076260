import React from 'react';

import { useTranslation } from 'react-i18next';

const TeamMemberItemThree = ({ data }) => {
  const { t } = useTranslation();
  return (
    <div className="team team__style--3">
      <div className="thumb">
        <img src={require('assets/img/team/' + data.thumb)} alt={data.name} />
        {data.socials ? (
          <div
            className="overlay"
            style={{
              backgroundImage: `url(${require('assets/img/team/' +
                data.thumb)})`
            }}
          />
        ) : null}

        <ul className="social-icon icon-solid-rounded icon-size-medium text-center">
          {data.socials
            ? data.socials.map((social) => (
                <li key={social.id} className={social.media}>
                  <a
                    href={`https://${social.media}.com/${social.username}`}
                    className="link"
                    aria-label={social.media}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <i className={`fa fa-${social.media}`} />
                  </a>
                </li>
              ))
            : null}
        </ul>
      </div>

      <div className="team-info">
        <div className="info">
          <p className="team-name">{data.name}</p>
          <span>{data.designation}</span>
          <p className="team-desc">{t(data.description)}</p>
        </div>
      </div>
    </div>
  );
};

export default TeamMemberItemThree;
