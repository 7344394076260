import { Helmet } from 'react-helmet';
import brook from 'data/brook.json';
import React, {
  Fragment,
  useLayoutEffect,
  useRef,
  useState,
  useEffect
} from 'react';
import { useTranslation } from 'react-i18next';

import Footer from 'container/footer/FooterOne';
import LayoutDefault from 'layouts/LayoutDefault';
import Header from 'components/header/Header';
import Awards from 'container/awards/homes/Awards';
import Counter from 'container/counter/Counter';
import Testimonial from 'container/testimonial/elements/TestimonialAirbag';
import DemoRequestSection from 'container/contact/DemoRequestSection';
import { ReactComponent as ModalImage } from 'assets/img/pricings/modal_image.svg';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';

const ContactAirbag = () => {
  gsap.registerPlugin(ScrollTrigger);
  const { t } = useTranslation();
  const [modalView, setModalView] = useState(false);

  const scrollAnimationRef = useRef();

  useLayoutEffect(() => {
    const q = gsap.utils.selector(scrollAnimationRef);

    // remove panel interaction on mobile

    if (window.innerWidth <= 992) {
      const listP = document.querySelectorAll('.remove-panel-mobile');
      listP.forEach((element) => {
        element.classList.remove('panel');
      });
    }

    // Logo color

    gsap.to(q('.letter-logo'), {
      fill: 'white',
      ease: 'none',
      scrollTrigger: {
        trigger: q('.brook-our-awards'),
        start: 'top 10%',
        end: '1%',
        scrub: true
        // markers: true,
      }
    });

    //language

    if (window.innerWidth > 992) {
      gsap.to('.globe-l', {
        stroke: 'white',
        ease: 'none',
        scrollTrigger: {
          trigger: '.brook-our-awards',
          start: 'top 10%',
          end: '1%',
          scrub: true
          // markers: true,
        }
      });

      gsap.to('.globe-indicator', {
        color: 'white',
        ease: 'none',
        scrollTrigger: {
          trigger: '.brook-our-awards',
          start: 'top 10%',
          end: '1%',
          scrub: true
          // markers: true,
        }
      });
    }

    gsap.to(q('.dot-logo'), {
      fill: 'rgb(255, 230, 0)',
      ease: 'none',
      scrollTrigger: {
        trigger: q('.change-logo-dot-normal'),
        start: 'top 10%',
        end: '5%',
        scrub: true
        // markers: true,
      }
    });

    gsap.utils.toArray(q('.panel')).forEach((panel) => {
      gsap.timeline({
        scrollTrigger: {
          trigger: panel,
          start: 'bottom bottom',
          end: 'bottom top',
          pin: true,
          pinSpacing: false,
          scrub: true
          // markers: true,
        }
      });
    });

    return () => {
      // console.log(ScrollTrigger.getAll());
      ScrollTrigger.getAll().forEach((st) => st.kill());
      // console.log(ScrollTrigger.getAll());
    };
  }, []);

  useEffect(() => {
    const timer = setTimeout(() => setModalView(true), 4000);
    return () => clearTimeout(timer);
  }, []);

  const modal = () => {
    return (
      <>
        <div
          className="details-modal-overlay"
          onClick={() => setModalView(false)}
        ></div>
        <div className="details-modal">
          <div
            className="details-modal-close"
            onClick={() => setModalView(false)}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M13.7071 1.70711C14.0976 1.31658 14.0976 0.683417 13.7071 0.292893C13.3166 -0.0976311 12.6834 -0.0976311 12.2929 0.292893L7 5.58579L1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L5.58579 7L0.292893 12.2929C-0.0976311 12.6834 -0.0976311 13.3166 0.292893 13.7071C0.683417 14.0976 1.31658 14.0976 1.70711 13.7071L7 8.41421L12.2929 13.7071C12.6834 14.0976 13.3166 14.0976 13.7071 13.7071C14.0976 13.3166 14.0976 12.6834 13.7071 12.2929L8.41421 7L13.7071 1.70711Z"
                fill="black"
              />
            </svg>
          </div>
          <div className="details-modal-content">
            <ModalImage className="modal-image" />
            <div className="modal-text">
              <p
                className="content-modal-pricing"
                dangerouslySetInnerHTML={{
                  __html: t('pricing_modal_content_schedule')
                }}
              />
              <p
                className="content-modal-pricing-bold"
                dangerouslySetInnerHTML={{
                  __html: t('pricing_modal_content_demo')
                }}
              />
              <p
                className="content-modal-pricing"
                dangerouslySetInnerHTML={{
                  __html: t('pricing_modal_content_and')
                }}
              />
              <p
                className="content-modal-pricing-bold"
                dangerouslySetInnerHTML={{
                  __html: t('pricing_modal_content_months_bold')
                }}
              />
              <p
                className="content-modal-pricing"
                dangerouslySetInnerHTML={{
                  __html: t('pricing_modal_content_more')
                }}
              />
              <p
                className="content-modal-pricing-bold-yellow"
                dangerouslySetInnerHTML={{
                  __html: t('pring_modal_content_discount_bold')
                }}
              />
            </div>
          </div>
        </div>
      </>
    );
  };

  return (
    <Fragment>
      <Helmet>
        <title>{`Contacto | ${brook.title}`}</title>
      </Helmet>
      {modalView && modal()}
      <LayoutDefault
        className="template-color-4 template-font-1"
        refContainer={scrollAnimationRef}
      >
        <Header />

        <div className="bg_color--25">
          <DemoRequestSection />
        </div>

        <div className="panel change-logo-dot-normal min-vh-100 bg_color--1 d-flex flex-column justify-content-center">
          <Counter />
          <Testimonial />
        </div>

        <Awards bgColor={'black'} />
      </LayoutDefault>
      <Footer className="bg_color--3" />
    </Fragment>
  );
};

export default ContactAirbag;
