import React, { useLayoutEffect, useRef } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import Button from 'components/button';
import { ReactComponent as Analysis } from 'assets/img/animation/analysis.svg';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';

import { useTranslation } from 'react-i18next';

const AboutThree = () => {
  const { t } = useTranslation();
  gsap.registerPlugin(ScrollTrigger);

  const scrollAnimationRef = useRef();

  useLayoutEffect(() => {
    const q = gsap.utils.selector(scrollAnimationRef);

    // Third yellow section animation

    const animation1 = gsap.timeline({
      scrollTrigger: {
        trigger: scrollAnimationRef.current,
        start: 'top bottom',
        end: 'bottom top',
        scrub: 1
        // markers: true
      }
    });

    animation1
      .add('start')
      .from(q('.text-card'), { autoAlpha: 0, duration: 2 }, 'start')
      .to(q('.card-one'), { xPercent: -36, duration: 3 }, 'start')
      .from(q('.appear'), { autoAlpha: 0, duration: 2, delay: 1 }, 'start')
      .to(q('.card-two'), { xPercent: 20, duration: 3 }, 'start')
      .to(q('.card-three'), { yPercent: -60, duration: 5 }, 'start');
  }, []);

  return (
    <div className="bg_color--1">
      <div className="bk-about-area section-ptb-xl bg_color--24 round-radius-bottom">
        <Container fluid="sm">
          <Row
            className="about--creative scroll-animation-three"
            ref={scrollAnimationRef}
          >
            <Col xs={12} lg={6}>
              <Analysis />
            </Col>

            <Col xs={12} lg={6} className="mt_md--40 mt_sm--40">
              <div className="service airbag">
                <h3
                  dangerouslySetInnerHTML={{
                    __html: t('home_fact_three_title')
                  }}
                />

                <p
                  className="text-description"
                  dangerouslySetInnerHTML={{
                    __html: t('home_fact_three_description')
                  }}
                />

                <Button
                  type={'link'}
                  btnText={t('button_three')}
                  btnLink={'/x-ray'}
                  arrow={false}
                  size={'sd'}
                  color={'theme-secondary'}
                  btnStyle={'bullet'}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default AboutThree;
