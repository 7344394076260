import React, { useState } from 'react';
import Button from 'components/button';

const TestimonialCard = (props) => {
  const { name, description, job, image, videoUrl } = props;
  const [playVideo, setPlayVideo] = useState(false);
  const iframeAllowstring =
    'accelerometer; autoplay; clipboard-write; ' +
    'encrypted-media; gyroscope; picture-in-picture';

  return (
    <>
      <div id="testimonial-container">
        <div className="wrap animate pop">
          <div className="overlay">
            <div className="overlay-content animate slide-left delay-2">
              <h1 className="animate slide-left pop delay-4">{name}</h1>
              <p className="animate slide-left pop delay-5">{job}</p>
            </div>
            <div
              className="image-content animate slide delay-5"
              style={{
                backgroundImage: `url(${require('assets/img/testimonial/' +
                  image)})`
              }}
            ></div>
            <div className="dots animate">
              <div className="dot animate slide-up delay-6"></div>
              <div className="dot animate slide-up delay-7"></div>
              <div className="dot animate slide-up delay-8"></div>
            </div>
          </div>
          <div className="text">
            <div className="button-testimonial-card">
              <Button
                type={''}
                onClick={() => {
                  setPlayVideo(true);
                }}
                btnText={'Ver video'}
                arrow={true}
                size={'sd'}
                color={'theme'}
                btnStyle={'bullet'}
              />
            </div>
            <p>{description}</p>
          </div>
        </div>
      </div>
      {playVideo && (
        <div id="_manual-video_">
          <div
            id="manuals-courtain"
            onClick={() => {
              setPlayVideo(false);
              document.body.classList.remove('no-scroll');
            }}
          >
            <div id="courtain-content">
              <iframe
                src={videoUrl}
                allow={iframeAllowstring}
                allowFullScreen
                style={{ border: 'none' }}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default TestimonialCard;
