import React, { useEffect, useState } from 'react';
import Button from 'components/button';
// import { Link } from 'react-router-dom';
import i18next from 'i18next';
import Cookies from 'js-cookie';
import { ReactComponent as Globe } from 'assets/img/icons/globe.svg';

import { useTranslation } from 'react-i18next';

import gsap from 'gsap';

const HeaderConfigOne = () => {
  const { t } = useTranslation();
  const [currentLanguage, setCurrentLanguage] = useState(
    Cookies.get('i18next') || 'es'
  );

  const body = document.querySelector('body');
  const html = document.querySelector('html');

  const mobileMenuHandler = () => {
    body.classList.add('popup-mobile-menu-wrapper');
    html.style.overflow = 'hidden';
  };

  useEffect(() => {
    // const button = document.querySelector('.log-in-link');
    // const over = document.querySelector('.over');

    // var tl = gsap.timeline()

    // tl.fromTo(over, { x: 0 }, {
    //     duration: 0.8,
    //     x: "102%"
    // }).reverse()

    // over.addEventListener("mouseover", () => {
    //     tl.play()
    // })

    // button.addEventListener("mouseleave", () => {
    //     tl.reverse()
    // })

    if (window.innerWidth > 992) {
      gsap.utils.toArray('.log-in-link').forEach((el) => {
        let items = el.querySelectorAll('.over');

        var tl = gsap.timeline();

        tl.fromTo(
          items,
          { x: 0 },
          {
            duration: 0.8,
            x: '100%'
          }
        ).reverse();

        items.forEach((item) => {
          item.addEventListener('mouseover', () => {
            tl.play();
          });
        });

        el.addEventListener('mouseleave', () => {
          // tl.timeScale(3).reverse()
          tl.reverse();
        });
      });
    }
  }, []);

  return (
    // pl--35 pl_md--5 pl_sm--5
    <div className="header-right have-not-flex">
      <div className="header-buttons dark-logo--version d-none d-lg-flex">
        <Button
          type={'link'}
          btnText={t('button_two')}
          btnLink={'/solicitud-de-demo'}
          arrow={true}
          size={'sd'}
          color={'theme'}
          btnStyle={'bullet'}
          className={'header-right-button'}
        />
        <div className="log-in-link">
          <a href="https://app.airbagtech.io" className="">
            <span>{t('button_one')}</span>
            <div className="over">
              <span>{t('button_one')}</span>
            </div>
          </a>
        </div>

        {currentLanguage === 'es' ? (
          <button
            className="lenguage-b globe-indicator"
            style={{
              border: `none`,
              padding: `0`,
              display: `flex`,
              alignItems: `center`
            }}
            onClick={() => {
              i18next.changeLanguage('en');
              setCurrentLanguage('en');
            }}
            disabled={'en' === currentLanguage}
          >
            <div
              style={{
                display: `flex`,
                alignItems: `center`,
                marginRight: `6px`
              }}
            >
              <Globe />
            </div>
            <span>EN</span>
          </button>
        ) : (
          <button
            className="lenguage-b globe-indicator"
            style={{
              border: `none`,
              padding: `0`,
              display: `flex`,
              justifyContent: `center`,
              alignItems: `center`
            }}
            onClick={() => {
              i18next.changeLanguage('es');
              setCurrentLanguage('es');
            }}
            disabled={'es' === currentLanguage}
          >
            <div
              style={{
                display: `flex`,
                alignItems: `center`,
                marginRight: `6px`
              }}
            >
              <Globe />
            </div>
            <span>ES</span>
          </button>
        )}
      </div>

      <div
        className="menu-hamburger popup-mobile-click light-version d-block d-lg-none"
        onClick={() => {
          mobileMenuHandler();
        }}
      >
        <div>
          <i />
        </div>
      </div>
    </div>
  );
};

export default HeaderConfigOne;
