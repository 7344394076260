import React, { Fragment, useEffect } from 'react';
import brook from 'data/brook.json';
import { Helmet } from 'react-helmet';
import LayoutDefault from 'layouts/LayoutDefault';
import ContactSupportContainer from 'container/contact-support/ContactSupportContainer';

const AppLink = () => {
  useEffect(() => {
    // Redirect to Diri whatsapp number
    window.location.replace('https://wa.me/525639447992');
  }, []);

  return (
    <Fragment>
      <Helmet>
        <title>{`Soporte | ${brook.title}`}</title>
      </Helmet>

      <LayoutDefault className="template-color-1 template-font-1">
        <ContactSupportContainer />
      </LayoutDefault>
    </Fragment>
  );
};

export default AppLink;
